var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Promo Item' : 'Create New Promo Item')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Equivalent Price")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
      { 'has-danger': failed },
      { 'has-success': passed }],attrs:{"filterable":"","remote":"","remote-method":_vm.getPrices,"loading":_vm.loading},model:{value:(_vm.form.price_id),callback:function ($$v) {_vm.$set(_vm.form, "price_id", $$v)},expression:"form.price_id"}},_vm._l((_vm.prices),function(price){return _c('el-option',{key:price.id,attrs:{"label":price.route.code+' '+price.accommodation.code+' '+_vm.$store.state.passenger_types[price.passenger_type],"value":price.id}})}),1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Amount")]),_c('ValidationProvider',{attrs:{"name":"amount"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.amount),callback:function ($$v) {_vm.$set(_vm.form, "amount", $$v)},expression:"form.amount"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Limit of Passengers per accommodation")]),_c('ValidationProvider',{attrs:{"name":"amount"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.limit),callback:function ($$v) {_vm.$set(_vm.form, "limit", $$v)},expression:"form.limit"}})],1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Return trip Amount")]),_c('ValidationProvider',{attrs:{"name":"amount"}},[_c('el-input-number',{staticClass:"form-group",attrs:{"placeholder":"0"},model:{value:(_vm.form.return_trip_amount),callback:function ($$v) {_vm.$set(_vm.form, "return_trip_amount", $$v)},expression:"form.return_trip_amount"}})],1)],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }