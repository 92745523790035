<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Upgrade Ticket
          </h4>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12" v-if="ticket">
              <div class="bold-text-blue">{{ ticket.ticket_detail.passenger.full_name }}</div>
              <div v-if="ticket.ticket_seat">SEAT NUMBER: {{ ticket.ticket_seat.seat_number }}</div>
              <hr>
              <div>Current Accommodation: {{ ticket.accommodation.name }}</div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>New Accommodation</label>
                <ValidationProvider name="accommodation" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.new_accommodation_id" placeholder="Select" filterable class="form-group"
                    :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="accommodation.id" :label="accommodation.name"
                      v-for="accommodation in accommodations" :key="accommodation.id">{{ accommodation.name
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Accommodation is required</span>
                </ValidationProvider>
              </div>

              <el-button type="primary" native-type="submit" plain :disabled="!form.new_accommodation_id"
                v-if="!booking">Check</el-button>
            </div>
            <div class="col-md-12">
              <el-alert type="success" v-if="booking">Upgrade is available</el-alert>

            </div>
          </div>
        </div>
      </div>
      <div class=" text-right" style="margin-bottom: 10px">
        <el-button type="primary" native-type="button" :disabled="!form.new_accommodation_id" v-if="booking"
          @click="onSubmit">SAVE <span v-if="seat_number">With Seat Number {{ seat_number }}</span>
          <span> | {{ money(getDifference()) }} CHARGE </span></el-button>
      </div>

      <div v-if="booking && ticket" style="text-align: center;">
        <div class="text-primary">Note: You may choose a seat number during upgrade.</div>
        <ticket-assign-seat :ticket="ticket" :booking="ticket.booking" @available="setSeatNumber($event)"
          :checkOnly="true" :center="true" :accommodation_id="form.new_accommodation_id"
          :accommodations="accommodations"></ticket-assign-seat>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Ticket
} from '@/resources/ticket'

import {
  Accommodation
} from '@/resources/accommodation'

import {
  Booking
} from '@/resources/booking'

import Swal from 'sweetalert2'
import TicketAssignSeat from './TicketAssignSeat.vue';

extend("required", required);

export default {
  components: { TicketAssignSeat },
  props: ['ticket'],
  data() {
    return {
      form: {
        new_accommodation_id: null
      },
      accommodations: [],
      booking: null,
      price: null,
      seat_number: null
    };
  },
  watch: {
    ticket: function () {
      if (this.ticket) {
        this.form = {
          id: this.ticket.id,
          accommodation_id: this.ticket.accommodation.id
        }
        this.getAccommodations()
      }
    },
    'form.new_accommodation_id': function () {
      this.booking = null
      this.price = null
    }
  },
  created: function () {
    if (this.ticket) {
      this.form = {
        id: this.ticket.id,
        accommodation_id: this.ticket.accommodation.id
      }
      this.getAccommodations()
    }
  },
  methods: {
    setSeatNumber(seat_number) {
      this.seat_number = seat_number
    },
    getAccommodation(id) {
      let accommodation
      this.accommodations.forEach(function (acc) {
        if(acc.id == id) accommodation = acc
      })

      return accommodation
    },
    getDifference() {
      let accommodation = this.getAccommodation(this.form.new_accommodation_id)
      return Math.max(this.price.amount - this.ticket.ticket_detail.fare, 0);  
    },
    async onSubmit() {
      let accommodation = this.getAccommodation(this.form.new_accommodation_id)
      let difference = Math.max(this.price.amount - this.ticket.ticket_detail.fare, 0);  
   
      // const { value: res } = await Swal.fire({
      //   title: "Upgrade to " + accommodation.name,
      //   text: "This will charge the ticket " + this.money(difference),
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Proceed",
      //   confirmButtonClass: "btn btn-success",
      //   cancelButtonClass: "btn btn-info",
      //   buttonsStyling: false
      // })

      // if (!res) return


      this.$loading = true
      this.form.seat_number = this.seat_number

      Ticket.upgrade(this.form.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been upgrade',
          });

          // var printWindow = window.open(result.data.url, '_blank');
          // printWindow.print();

          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    submit() {
      this.getBooking()
    },
    getBooking() {
      this.$loading = true
      Booking.getBooking({
        date: this.$moment(this.ticket.booking.booking_date).format('YYYY-MM-DD'),
        route_code: this.ticket.booking.route.code,
        time: this.ticket.booking.time,
        accommodation_id: this.form.new_accommodation_id,
        passenger_type: this.ticket.passenger_type,
        include_price: true
      })
        .then(result => {
      
          if (result.data.booking)
            this.booking = result.data.booking
          this.form.booking_id = result.data.booking.id
          this.price = result.data.price
          this.seat_number = null
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getAccommodations() {
      this.$loading = true
      this.booking = null
      Accommodation.get({higher_order_than: this.ticket.accommodation.upgrade_order })
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
          if (this.accommodations.length == 1) this.form.new_accommodation_id = this.accommodations[0].id
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
