<template>
  <div class="">
    <el-card>
      <h5>{{ title || 'Most Popular Routes (Past 30 Days)' }}</h5>
          <bar-chart :labels="viewsChart.labels"
                       :height="height || 350"
                       :datasets="viewsChart.datasets" v-if="visible">
            </bar-chart>
    </el-card>
          
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import BarChart from 'src/components/UIComponents/Charts/BarChart'

const tooltipOptions = {
  tooltipFillColor: "rgba(0,0,0,0.5)",
  tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipFontSize: 14,
  tooltipFontStyle: "normal",
  tooltipFontColor: "#fff",
  tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
  tooltipTitleFontSize: 14,
  tooltipTitleFontStyle: "bold",
  tooltipTitleFontColor: "#fff",
  tooltipYPadding: 6,
  tooltipXPadding: 6,
  tooltipCaretSize: 8,
  tooltipCornerRadius: 6,
  tooltipXOffset: 10,
};

export default {
  props: ['filter', 'title', 'update', 'height'],
  components: {
   BarChart
  },
  watch: {
    update: function(){
      this.init()
    }
  },
  data() {
    return {
      visible: false,
      routes: [],
      start_date: null,
      end_date: null,
      viewsChart: {
        labels: [],
        datasets: [
          {
            label: "Route",
            borderColor: '#6cffff',
            backgroundColor: '#c0ffff',
            hoverBorderColor: '#6cffff',
            borderWidth: 5,
            data: [],
          }
        ]
      },
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init(){
      this.start_date = this.$moment().subtract(1, 'months');
      this.end_date = this.$moment()

      if (this.filter) {
        if (this.filter.start_date) this.start_date = this.$moment(this.filter.start_date);
        if (this.filter.end_date) this.end_date = this.$moment(this.filter.end_date);
      }
      this.getTopRoutes()
    },
    getTopRoutes() {
      let params = { start_date: this.start_date.format('YYYY-MM-DD'),  end_date: this.end_date.format('YYYY-MM-DD') }
      if (this.filter && this.filter.online_only) params.is_online = 1
      if (this.filter && this.filter.sold_date) params.sold_date = 1
      this.$loading = true
      this.visible = false
      Analytic.topRoutes(params)
        .then(result => {
          this.routes = result.data.routes
          this.collectData(result.data.routes)
        })
        .catch(() => {
        
        }).finally(() => {
          this.$loading = false
        })
    },
    collectData(routes) {
      this.viewsChart.labels = []
      let counts = []
      let labels = []
      routes.forEach(route => {
        labels.push(route.route_code)
        counts.push(route.count)
      });
      this.viewsChart.datasets[0].data = counts
      this.viewsChart.labels= labels
      this.visible = true
    }
  }
}
</script>
<style lang="scss"></style>
