<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)" v-loading="loading">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update user' : 'Create new user' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed"
                name="name" v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Contact Number</label>
            <ValidationProvider name="name" rules="" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The contact number field is required' : null" :hasSuccess="passed"
                name="name" v-model="form.contact_number">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Allow SMS</label><br>
            <el-switch v-model="form.allow_sms"></el-switch>
          </div>
          <div class="form-group">
            <label>Password</label>
            <ValidationProvider name="password" :rules="{
                regex: /^(?=.*\d).{8,}$/
              }" v-slot="{ passed, failed }">
              <el-input
                :placeholder="form.id ? 'No changes will occur to the password if left blank':'Will generate default password if leave blank'"
                v-model="form.password" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]"></el-input>
              <span class="error-text" v-if="failed">Should consist of a minimum of 8 characters, including
                numbers</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Email</label>
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The email field format is invalid' : null" :hasSuccess="passed"
                name="email" v-model="form.email">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Branch</label>
            <ValidationProvider name="branch_id" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.branch_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="p.id" :label="p.name" v-for="p in branches" :key="p.id">{{ p.name }}</el-option>
              </el-select><br>
              <span class="error-text" v-if="failed">Branch is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Role</label>
            <el-select v-model="form.role_names" multiple filterabl placeholder="Select roles" :loading="$loading">
              <el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.name">
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>Status</label>
            <el-select v-model="form.status">
              <el-option :value="0" label="Pending">Pending</el-option>
              <el-option :value="1" label="Active">Active</el-option>
              <el-option :value="2" label="Inactive">Inactive</el-option>
            </el-select>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  regex,
  email
} from "vee-validate/dist/rules";
import {
  ClientUser
} from '@/resources/client_user'
import {
  Branch
} from '@/resources/branch'
import {
  Role
} from '@/resources/role'

extend("email", email);
extend("required", required);
extend("regex", regex);

export default {
  props: ['user'],
  data() {
    return {
      form: {
        email: "",
        name: "",
      },
      loading: false,
      roles: [],
      branches: []
    };
  },
  watch: {
    user: function () {
      if (this.user) this.form = Object.assign({}, this.user)
      if (this.form.branch) this.form.branch_id = this.form.branch.id
    }
  },
  created: function () {
    if (this.user) this.form = Object.assign({}, this.user)
    if (this.form.branch) this.form.branch_id = this.form.branch.id
    this.getBranches()
    this.getClientRoles()
  },
  methods: {
    getClientRoles() {
      let params = { page: 1, per_page: 9999, with_client_roles: true }
      Role.get({ params: params })
        .then(result => {
          if (result.data.roles)
            this.roles = result.data.roles
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch roles',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    getBranches() {
      let params = {}
      this.$loading = true
      Branch.get({
          params: params
        })
        .then(result => {
          if (result.data.branches)
            this.branches = result.data.branches
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch branches',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    submit() {
      this.loading = true

      let request = this.form.id ? ClientUser.update(this.form) : ClientUser.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'User has been saved',
          });
          this.$emit('save', result.data.user)
        })
        .catch(error => {

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
