import api from '../api'

const API = '/api/client/user-transactions'

export const ClientUserTransactionRecord = {
  get: function(params) {
    return api.get(API, {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  recalculate: function(form) {
    return api.create(API+'/recalculate', form)
  },
  approve: function(id,form) {
    return api.update(API+'/'+id+'/approve', form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
