<template>
  <div class="row">

    <!-- <div class="col-md-3">
      <stats-card :type="'success'" icon="fa fa-money" :small-title="'PAID INVOICES'" :title="money(paid_invoices)">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div> -->

    <div class="col-md-3">
      <stats-card :type="'warning'" icon="fa fa-exclamation-circle" :small-title="'UNPAID INVOICES'"
        :title="money(unpaid_invoices)">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div>


    <div class="col-md-3">
      <stats-card :type="'danger'" icon="fa fa-window-close" :small-title="'OVERDUE INVOICES'"
        :title="money(overdue_invoices)">
        <div class="stats link" slot="footer">
          <i :class="'fa fa-external-link'"></i>
        </div>
      </stats-card>
    </div>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Invoices</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="select-default" v-model="status" placeholder="Per page" size="small">
            <el-option class="select-default" label="Unpaid" :value="0"></el-option>
            <el-option class="select-default" label="Paid" :value="1"></el-option>
            <el-option class="select-default" label="Past Due" :value="2"></el-option>

          </el-select>
          <!-- <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button> -->
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="invoices" style="width: 100%">
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Invoice No.">
              <template slot-scope="props">
                {{ props.row.invoice_number }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Title">
              <template slot-scope="props">
                {{ props.row.title }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Amount">
              <template slot-scope="props">
                {{ money(props.row.total_amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Status">
              <template slot-scope="props">
                {{ $store.state.invoice_status[props.row.status] }}
              </template>
            </el-table-column>


            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Action">
              <template slot-scope="props">
                <el-button type="default" link @click="handleDownload(props.row)" size="mini">
                  <i class="fa fa-download"></i>
                </el-button>
              </template>
            </el-table-column>


          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ClientInvoice
} from '@/resources/client/client_invoice'
import StatsCard from '../../../UIComponents/Cards/StatsCard.vue'

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
    
      invoices: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      invoice_date: null,
      paid_invoices: 0,
      unpaid_invoices: 0,
      overdue_invoices: 0,
      status: 0
    }
  },
  watch: {
    status: function(){
      this.getInvoices()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getInvoices()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getInvoices()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getInvoices()
    }
  },
  created: function () {
    let date = this.$moment().subtract(1, 'months');
    this.invoice_date = date.format('MMM YYYY')

    this.getInvoices()
  },
  methods: {
    handleEdit(invoice) {
      this.showForm = true
      this.selected = Object.assign({}, invoice)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
      this.showGenerate = false
    },
    handleForm() {
      this.showForm = true
    },
    handleGenerate() {
      let date = this.$moment().subtract(1, 'months');

      this.selected = {
        start_date: date.startOf('month').format('YYYY-MM-DD'),
        end_date: date.endOf('month').format('YYYY-MM-DD')
      }
      this.showGenerate = true
    },
    handleDownload(invoice) {
      this.$loading = true
      this.$loadingText = "Downloading"

      ClientInvoice.downloadPdf(invoice.id)
        .then(result => {
          var printWindow = window.open(result.data.pdf.link, '_blank');
          printWindow.print();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },

    getInvoicesStatus(list) {
      let self = this
      self.unpaid_invoices = 0
      self.paid_invoices = 0
      self.overdue_invoices = 0
      list.forEach(item => {
        if (item.status == 0) self.unpaid_invoices = item.total_amount
        if (item.status == 1) self.paid_invoices = item.total_amount
        if (item.status == 2) self.overdue_invoices = item.total_amount
      });
    },
    getInvoices() {
      this.handleClose()
      let params = {
        order_asc: 1,
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        status: this.status
      }
      this.$loading = true
      ClientInvoice.get({
        params: params
      })
        .then(result => {
          if (result.data.invoices)
            this.invoices = result.data.invoices
          this.pagination = result.data.meta
          this.getInvoicesStatus(result.data.list)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
