<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Invoice '+form.invoice_number : 'Create new Invoice' }}
          </h4>
        </div>
        <div class="card-body">

          <div class="form-group">
            <label>Status</label>
            <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.status" placeholder="Select">
                <el-option v-for="item,i in $store.state.invoice_status" :key="i" :label="item" :value="i" :class="[
                       { 'has-danger': failed },
                       { 'has-success': passed }]">
                </el-option>
              </el-select>
              <br><span class="error-text" v-if="failed">Status is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Start Date</label>
            <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
              <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              </el-date-picker>
              <br><span class="error-text" v-if="failed">Start Date is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>End Date</label>
            <ValidationProvider name="end_date" rules="required" v-slot="{ passed, failed }">
              <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              </el-date-picker>
              <br><span class="error-text" v-if="failed">End Date is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Due Date</label>
            <ValidationProvider name="due_date" rules="required" v-slot="{ passed, failed }">
              <el-date-picker v-model="form.due_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
              </el-date-picker>
              <br><span class="error-text" v-if="failed">Due Date is required</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Title</label>
            <ValidationProvider name="title" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The title field format is invalid' : null" :hasSuccess="passed"
                name="code" v-model="form.title">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Remarks</label>
            <el-input type="textarea" v-model="form.remarks"></el-input>
          </div>

          <table class="table">
            <thead>
              <tr>
                <th style="width: 50%">Description</th>
                <th style="width: 15%">Quantity</th>
                <th style="width: 15%">Unit Price</th>
                <th style="text-align: right;">Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="invoice_item,i in form.invoice_items || []" :key="invoice_item.id">
                <td>
                  <ValidationProvider :name="'item_name_'+i" rules="required" v-slot="{ passed, failed }">
                    <fg-input type="textarea" :error="failed ? 'Item description is required' : null"
                      :hasSuccess="passed" name="code" v-model="invoice_item.item_name">
                    </fg-input>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider :name="'quantity_' + i" rules="">
                    <el-input v-model="invoice_item.quantity" :min="0" type="number" step=".01"></el-input>
                  </ValidationProvider>
                </td>
                <td>
                  <ValidationProvider :name="'unit_price_' + i" rules="">
                    <el-input v-model="invoice_item.unit_price" :min="0" type="number" step=".01"></el-input>
                  </ValidationProvider>
                </td>
                <td style="text-align: right;">
                  {{ money(invoice_item.quantity * invoice_item.unit_price) }}
                </td>

                <td style="text-align: right;">
                  <el-button type="text" class="text-danger" @click="handleRemove(invoice_item)"><i
                      class="fa fa-trash"></i></el-button>
                </td>
              </tr>
              <tr>
                <td colspan="4" style="text-align: right;">{{ money(getTotal()) }}</td>
              </tr>
              <tr>
                <td colspan="4" ><el-button text @click="handleAddItem">ADD ITEM <i class="fa fa-plus"></i></el-button></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Invoice
} from '@/resources/invoice'


extend("required", required);

export default {
  props: ['invoice'],
  data() {
    return {
      form: {
        fein: "",
        name: "",
      }
    };
  },
  watch: {
    client: function () {
      if (this.invoice) this.form = Object.assign({}, this.invoice)
    }
  },
  created: function () {
    if (this.invoice) this.form = Object.assign({}, this.invoice)
  },
  beforeDestroy() {
    this.form = {
      fein: "",
      name: "",
    }
  },
  methods: {
    handleAddItem() {
      let item = {
        invoice_id: this.invoice.id,
        item_name: "",
        quantity: 0,
        total_price : 0, 
        unit_price : 0
      }

      this.form.invoice_items.push(item)
    },
    getTotal() {
      let total = 0
      let items = this.form.invoice_items || []

      items.forEach(item => {
        item.total_price = item.quantity * item.unit_price
        total += item.total_price
      });

      this.form.total_amount = total
      return total
    },
    handleRemove(i) {
      let items = this.form.invoice_items || []
      let newItems = []
      items.forEach(item => {
        if (item.id != i.id) newItems.push(item)
      });

      this.form.invoice_items = newItems
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? Invoice.update(this.form) : Invoice.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
