<template>
  <div>
    <hr>
    <div v-if="rescheduled_tickets.length">

      <div>History of Rescheduled Events</div>
      <br>
      <div v-for="rt in rescheduled_tickets" :key="rt.id">
        <div>
          <ul class="list-unstyled team-members">
            <li>
              <div class="row">
                <div class="col-sm-5 col-12 text-muted" style="text-align: center;">
                  {{ rt.old_booking.route_code + ' | ' + rt.old_booking.booking_date }}
                  <br>
                  {{ rt.old_booking.formatted_time }}
                </div>
                <div class="col-sm-1 text-muted" style="text-align: center;">
                  TO
                </div>
                <div class=" col-sm-5 text-muted" style="text-align: center;">
                  {{ rt.new_booking.route_code + ' | ' + rt.new_booking.booking_date }}
                  <br>
                  {{ rt.new_booking.formatted_time }}
                </div>
              </div>
            </li>
          </ul>
          <hr>
        </div>
      </div>
    </div>
    <div v-else>No reschedules recorded.</div>
  </div>
</template>
<script>

import { Ticket } from '@/resources/ticket'

export default {
  props: ['ticket'],
  components: {},
  data() {
    return {
      rescheduled_tickets: []
    }
  },
  watch: {
    ticket: function () {
      if (this.ticket) this.getReschedulesHistory()
    }
  },
  created: function () {
    if (this.ticket) this.getReschedulesHistory()
  },
  methods: {
    getFrom(rt) {
      return rt.old_booking.route_code + ' | ' + rt.old_booking.booking_date + '|' + rt.old_booking.formatted_time
    },
    getTo(rt) {
      return rt.new_booking.route_code + ' | ' + rt.new_booking.booking_date + '|' + rt.new_booking.formatted_time
    },
    getHistoryText(rt) {
      return this.getFrom(rt) + "-> " + this.getTo(rt)
    },
    getReschedulesHistory() {
      this.$loading = true

      Ticket.reschedulesHistory(this.ticket.id)
        .then(result => {
          this.rescheduled_tickets = result.data.rescheduled_tickets
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
<style></style>
