<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Trip' : 'Create Trips' }}
          </h4>
        </div>
        <div class="card-body">

          <div class="form-group">
              <label>Days</label>
              <ValidationProvider name="days" rules="required" v-slot="{ passed, failed }">
                <el-select v-model="form.days" placeholder="Select" filterable class="form-group" multiple :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
                  <el-option :value="i" :label="day" v-for="day,i in days" :key="day">{{ day }}</el-option>
                </el-select><br>
                <span class="error-text" v-if="failed">At least one day is required</span>
              </ValidationProvider>
            </div>


          <div class="form-group">
            <label>Route</label>
            <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
              <el-select v-model="form.route_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
                <el-option :value="route.id" :label="route.code" v-for="route in routes" :key="route.id">{{ route.code }}</el-option>
              </el-select><br>
              <span class="error-text" v-if="failed">Route is required</span>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Vessel</label>
              <ValidationProvider name="vessel" rules="required" v-slot="{ passed, failed }">
                <el-select v-model="form.vessel_detail_id" placeholder="Select" filterable class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
                  <el-option :value="vessel.id" :label="vessel.name" v-for="vessel in vessels" :key="vessel.id">{{ vessel.name }}</el-option>
                </el-select><br>
                <span class="error-text" v-if="failed">Vessel is required</span>
              </ValidationProvider>
            </div>

            <div class="form-group">
                <label>Time</label>
                <ValidationProvider name="vessel" rules="required" v-slot="{ passed, failed }">
                   <el-time-select
                   class="form-group" :class="[
                     { 'has-danger': failed },
                     { 'has-success': passed }]"
                    v-model="form.time"
                    :picker-options="{
                      start: '03:00',
                      step: '00:5',
                      end: '20:00'
                    }"
                    placeholder="Select time">
                  </el-time-select>
                  <br>
                  <span class="error-text" v-if="failed">Time is required</span>
                </ValidationProvider>
              </div>

        
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  TripSchedule
} from '@/resources/trip_schedule'

import {
  Route
} from '@/resources/route'
import {
  Vessel
} from '@/resources/vessel'


extend("required", required);

export default {
  props: ['trip_schedule'],
  data() {
    return {
      form: {
        days: [0]
      },
      days: this.$store.state.days,
      routes: [],
      vessels: []
    };
  },
  created: function () {
    this.getRoutes()
    this.getVessels()
  },
  methods: {
    submit() {
      this.$loading = true

      TripSchedule.addTrips(this.trip_schedule.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Trips has been added',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      Route.get({})
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getVessels() {
      this.$loading = true
      Vessel.get({})
        .then(result => {
          if (result.data.vessels)
            this.vessels = result.data.vessels
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
