<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
        <label>Roles</label>
        <el-select v-model="value" multiple filterable remote reserve-keyword placeholder="Please enter a keyword"
          :remote-method="remoteMethod" :loading="$loading">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="text-right"><p-button type="success" native-type="submit">Save</p-button></div>
    </form>
  </ValidationObserver>
</template>
<script>

import { User } from '@/resources/user'
import { Role } from '@/resources/role'

export default {
  props: ['user'],
  components: {},
  data() {
    return {
      value: [],
      options: [],
      form: {
        roles: []
      }
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
    this.getUserRoles()
  },
  methods: {
    getUserRoles() {
      let params = { page: 1, per_page: 10, user_id: this.user.id }
      Role.get({ params: params })
        .then(result => {
          if (result.data.roles)
            this.form.roles = result.data.roles
          let self = this
          this.form.roles.forEach(function (role) {
            self.value.push(role.name)
          })
          this.value = [...new Set(this.value)];
            console.log(this.value)
          // this.remoteMethod()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch roles',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.$loading = true
        let params = { term: query, page: 1, per_page: 10 }
        Role.get({ params: params })
          .then(result => {
            if (result.data.roles) {
              this.options = result.data.roles.map(role => {
                return { value: role.name };
              });
            }
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              title: "Error",
              text: 'Unable to fetch roles',
            });

          }).finally(() => {
            this.$loading = false
          })
      }
    },
    submit() {
      this.$loading = true

      User.updateRoles({ id: this.user.id, roles: this.value })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Roles updated',
          });
          this.$emit('update', true)

        })
        .catch(() => {
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
