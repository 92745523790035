<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update client' : 'Create new client' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Code</label>
            <ValidationProvider name="fein" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The code field format is invalid' : null" :hasSuccess="passed"
                name="code" v-model="form.code">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Client } from '@/resources/client'

extend("required", required);

export default {
  props: ['client'],
  data() {
    return {
      form: {
        fein: "",
        name: "",
      }
    };
  },
  watch: {
    client: function () {
      if (this.client) this.form = Object.assign({}, this.client)
    }
  },
  created: function () {
    if (this.client) this.form = Object.assign({}, this.client)
  },
  beforeDestroy() {
    this.form = {
      fein: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? Client.update(this.form) : Client.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Client has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
