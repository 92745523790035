<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <div class="content">
          <div class="container">
            <div class="col-lg-4 col-md-6 ml-auto mr-auto">
              <el-card v-if="loading">
                <h5>Checking Token... <span v-loading="loading"></span></h5>
              </el-card>

              <div v-if="!loading">
                <reset-password-form v-if="!message"></reset-password-form>

                <el-alert
                  v-if="!loading && message"
                  type="warning"
                  :closable="false"
                  show-icon>
                  <template slot="title">
                    <span >{{ message }}</span>
                  </template>
                </el-alert>
              </div>
              

            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <background></background>
      </div>
    </div>
  </div>
</template>
<script>

import AppNavbar from '@/components/Dashboard/Views/Pages/Layout/AppNavbar'
import AppFooter from '@/components/Dashboard/Views/Pages/Layout/AppFooter'
import { Card, Button } from 'src/components/UIComponents';
import ResetPasswordForm from './ResetPasswordForm.vue';
import { Signup } from '@/resources/signup'


export default {
  components: {
    AppNavbar,
    AppFooter,
    Card,
    ResetPasswordForm,
    [Button.name]: Button
  },
  created: function () {
    this.checkToken()
  },
  methods: {
    checkToken() {
      this.loading = true
      Signup.checkToken({token: this.$route.query.token})
        .then(result => {

        })
        .catch(error => {
          this.message = error.response.data.message
        }).finally(() => {
          this.loading = false
        })
    }
  },
  data() {
    return {
      email: null,
      message: null,
      loading: true
    }
  },
}
</script>
<style></style>
