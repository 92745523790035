<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update Trip Schedule' : 'Create New Trip Schedule' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

        <div class="form-group">
          <label>Start Date</label>
          <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
            <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
            </el-date-picker>
            <br><span class="error-text" v-if="failed">Start date is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>End Date</label>
          <ValidationProvider name="end_date" v-slot="{ passed, failed }" rules="required">
            <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
            </el-date-picker>
            <br><span class="error-text" v-if="failed">End date is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
            <label>Days to auto adjust end date</label>
            <ValidationProvider name="days_to_auto_adjust_end_date">
              <el-input-number v-model="form.days_to_auto_adjust_end_date" placeholder="0" class="form-group">
                </el-input-number>
            </ValidationProvider>
          </div>

      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  TripSchedule
} from '@/resources/trip_schedule'

extend("required", required);

export default {
  props: ['trip_schedule'],
  data() {
    return {
      form: {
        name: null,
        start_date: "",
        end_date: null
      }
    };
  },
  watch: {
    trip_schedule: function () {
      if (this.trip_schedule) this.form = Object.assign({}, this.trip_schedule)
    }
  },
  created: function () {
    if (this.trip_schedule) this.form = Object.assign({}, this.trip_schedule)
  },
  methods: {
    submit() {
      this.$loading = true
      this.form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      this.form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')

      let request = this.form.id ? TripSchedule.update(this.form) : TripSchedule.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Trip schedule has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
