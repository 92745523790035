<template>
  <div class="container" v-if="booking && form">
    <div style="font-weight: bold;font-size: 20px;margin-bottom: 20px;">SEND A NEW MESSAGE</div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group">
          <label>Contact Numbers</label>
          <el-tag v-for="(contact_number,i) in contact_numbers" :key="i">{{contact_number.contact_number }}</el-tag>
        </div>

        <div>
          <p>CLICK TO USE THESE MESSAGE TEMPLATES</p>
          <div v-for="(text,i) in getHelperMessages()" :key="i">
            <el-alert>
              <span> {{ text }}</span><br>
              <el-button @click="useMessage(text)" size="small">Use this</el-button>
            </el-alert>
          </div>
        </div>
        <div class="form-group">
          <label>Message</label>
          <ValidationProvider name="vessel" rules="required" v-slot="{ passed, failed }">
            <el-input :rows="5" type="textarea" placeholder="Type your message" v-model="form.message"
              class="form-group" :class="[
    { 'has-danger': failed },
    { 'has-success': passed }]">

            </el-input>
            <br> <span class="error-text" v-if="failed">Message is required</span>
            <div> {{ form.message.length }} of 160 characters. (You are only allowed to send up 160 characters)</div>
          </ValidationProvider>
        </div>
        <el-button size="medium" plain type="primary" native-type="submit"
          v-if="form.message.length && form.message.length <= 160">Save
          only</el-button>
        <confirm-button title="You're about to send this message. Proceed?"
          v-if="form.message.length && form.message.length <= 160" label="Save and Send" :size="'medium'"
          :type="'primary'" class="mt-3" @onClick="submit(true)">
        </confirm-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

extend("required", required);

import {
  Vessel
} from '@/resources/vessel'

import {
  Booking
} from '@/resources/booking'
import ConfirmButton from "../../../Common/ConfirmButton.vue";


export default {
  components: { ConfirmButton },
  props: ['booking', 'contact_numbers'],
  data() {
    return {
      form: {message: ""}
    };
  },
  watch: {
    booking: function () {
    }
  },
  created: function () {
  },
  methods: {
    useMessage(text) {
      this.form.message = text
    },
    getHelperMessages() {
      return [
        "WEESAM EXPRESS: Your trip "+this.booking.route.code+ " on " + this.booking.booking_date + " / " + this.booking.formatted_time + " has been canceled due to [Reason]. We apologize for the inconvenience.",
      ]
    },
    getContactNumbers() {
      let list = []

      this.contact_numbers.forEach(number => {
        list.push(number.contact_number)
      });

      return list
    },
    submit(send = false) {
      this.$loading = true

      this.form.booking_id = this.booking.id
      this.form.contact_numbers = this.getContactNumbers()
      if (send) this.form.to_send = send

      Booking.sendMessage(this.booking.id, this.form)
        .then(result => {
          this.form = { message: "" }
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Sending text message',
          });
          this.$emit('close', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
