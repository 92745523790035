<template>
  <div v-if="vessel_detail">
    <el-dialog title="" :visible.sync="showReservedSeats" size="50%" :destroy-on-close="true" :append-to-body="true">

      <div class="row">
        <div class="col-md-12">
          <div style="font-weight: bold;">RESERVED SEATS</div>
          <hr>
        </div>
        <div v-for="(seat, i) in blocked_seats" :key="i">
          <el-tooltip class="item" effect="dark" content="Remove" placement="top-start">
            <el-button @click="handleRemoveReservedSeat(seat)">{{ seat.seat_number }} <i
                class="fa fa-times"></i></el-button>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>


    <h4>{{ vessel_detail.code }} - {{ accommodation.name }}</h4>
    <small class="text-white active-seat padding-5">GRAY - AVAILABLE </small>
    <small class="text-white taken-seat padding-5">YELLOW - TAKEN </small>
    <small class="text-white reserved-seat padding-5">BLUE - RESERVED </small>
    <small class="text-white padding-5" style="background: #3e3f3f;">BLACK - UNAVAILABLE</small>
    <div v-if="allow_block">
      <br>
      <el-button @click="handleShowReservedSeats">SHOW {{ blocked_seats.length }} RESERVED SEATS</el-button>
    </div>
    <hr>
    <div class="row" v-if="!ticket && !booking">
      <div class="col-md-2 mb-2">
        <strong>Add Seats</strong>
        <el-input v-model="rows" size="mini">
          <template slot="prepend">Rows</template>
        </el-input>
        <el-input v-model="columns" size="mini">
          <template slot="prepend">Columns</template>
        </el-input>
        <div class="mt-2">
          <el-button size="mini" type="primary" plain @click="handleAddSeats()">Add</el-button>
          <el-button size="mini" type="danger" plain @click="handleRemoveAll()">or Remove all seats</el-button>
        </div>
      </div>
      <div class="col-md-3" v-if="vessel">
        TOTAL ACTIVE SEATS: <br>
        <div style="font-size: 50px;">{{ vessel.total }}</div>
      </div>

    </div>
    <div v-if="!generated_seats.length">No seats </div>
    <div v-for="row,i in generated_seats" :key="i" style="margin-bottom: 20px;min-width: 1000px;">
      <span class="seat-number-box" v-for="col in row.columns" :key="col" :class="{
    'aisle-seat': isAisle(AddLeadingZero(i + 1), col),
  'active-seat': isActive(AddLeadingZero(i + 1), col),
  'taken-seat': isSeatTaken(AddLeadingZero(i + 1), col),
  'reserved-seat': isSeatReserved(AddLeadingZero(i + 1), col),
  'current-seat': isCurrentSeat(AddLeadingZero(i + 1),col)
  }">
        <div style="padding-left: 5px;">
          <span class="seat-number-text">{{ AddLeadingZero(i + 1) }}{{ col }}</span><br>
          <el-link v-if="!ticket && !booking" style="font-size: 10px;"
            @click="handleAddSpace(AddLeadingZero(i + 1), col)">
            {{ isAisle(AddLeadingZero(i + 1), col) ? 'RM SPACE':'ADD SPACE'}}</el-link><br>
          <el-link v-if="!ticket && !booking" style="font-size: 10px;"
            @click="handleBlock(AddLeadingZero(i + 1), col)">{{ isActive(AddLeadingZero(i + 1), col) ? 'BLOCK' :
            'UNBLOCK' }}</el-link>
          <el-link
            v-if="ticket && (!isSeatTaken(AddLeadingZero(i + 1), col) || isSeatReserved(AddLeadingZero(i + 1), col)) && isActive(AddLeadingZero(i + 1), col)"
            @click="handleTakeSeat(AddLeadingZero(i + 1), col)" class="assign-seat-button"><span
              class="font-15 bold">TAKE</span> <i class="fa fa-sign-out"></i>
          </el-link>

          <el-link v-if="isCurrentSeat( AddLeadingZero(i + 1), col)" @click="handleRemoveSeat(AddLeadingZero(i + 1), col)"
            class="assign-seat-button"><span class="font-15 bold">REMOVE</span>
          </el-link>

          <el-link
            v-if="allow_block && booking && !isSeatReserved(AddLeadingZero(i + 1), col) && !isSeatTaken(AddLeadingZero(i + 1), col) && isActive(AddLeadingZero(i + 1), col) "
            @click="handleReserveSeat(AddLeadingZero(i + 1), col)" class="assign-seat-button"><span
              class="font-15 bold">RESERVE</span>
          </el-link>
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Vessel
} from '@/resources/vessel'
import {
  Accommodation
} from '@/resources/accommodation'

import {
  Booking
} from '@/resources/booking'

import {
  Ticket
} from '@/resources/ticket'

import {
  BookingBlockedSeat
} from '@/resources/booking_blocked_seat'

extend("required", required);

export default {
  props: ['vessel_detail', 'accommodation', 'ticket', 'taken_seats', 'booking', 'allow_block'],
  data() {
    return {
      loading: false,
      form: null,
      accommodations: [],
      seats: [],
      generated_seats: [],
      rows: 0,
      columns: 0,
      vessel: null,
      taken_seats_from_booking: [],
      blocked_seats: [],
      showReservedSeats: false,
      selected_seat_number: null
    };
  },
  watch: {
    accommodation: function () {
      this.getSeats()
    },
    ticket: function () {
      if (this.ticket && this.ticket.ticket_seat) this.selected_seat_number = this.ticket.ticket_seat.seat_number
    }
  },
  created: function () {
    if (this.ticket && this.ticket.ticket_seat) this.selected_seat_number = this.ticket.ticket_seat.seat_number

    this.getSeats()
  },
  methods: {
    isCurrentSeat(row, col) {
      return this.selected_seat_number == (row+col)
    },
    handleShowReservedSeats(){
      this.showReservedSeats = true
    },
    isSeatReserved(row,col) {
      let seat = row + col
      let is_reserved = false

      this.blocked_seats.forEach(function (blocked_seat) {
        if (blocked_seat.seat_number == seat) is_reserved = true
      })
      return is_reserved
    },
    handleTakeSeat(row, col) {
      this.$emit('takeSeat', row + col)
    },
    handleAddSpace(row, col) {
      let form = {
        seat_number: row + col,
        is_aisle: !this.isAisle(row, col),
        status: this.isActive(row, col),
        accommodation_id: this.accommodation.id,
        vessel_detail_id: this.vessel_detail.id
      }
      this.updateSeat(form)
    },
    handleBlock(row, col) {
      let form = {
        seat_number: row + col,
        is_aisle: this.isAisle(row, col),
        status: this.isActive(row, col) ? 0 : 1,
        accommodation_id: this.accommodation.id,
        vessel_detail_id: this.vessel_detail.id
      }
      this.updateSeat(form)
    },
    handleRemoveAll() {
      this.$loading = true

      Vessel.removeAllSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.seats = []
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    updateSeat(form) {
      this.$loading = true

      Vessel.updateSeat(this.vessel_detail.id, form)
        .then(result => {
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    AddLeadingZero(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    isSeatTaken(row, col) {
      let active = false
      let seats = this.taken_seats_from_booking || []
      if (!this.taken_seats_from_booking.length) seats = this.taken_seats || []
      seats.forEach(function (seat) {
        if (seat == row + col) {
          active = true
        }
      })
      return active
    },
    isActive(row, col) {
      let active = false
      let self = this

      this.seats.forEach(function (seat) {
        if (seat.seat_number == row + col) {
          if (seat.status == 1) active = true
        }
      })
      return active
    },
    isAisle(row, col) {
      let aisle = false
      let self = this

      this.seats.forEach(function (seat) {
        if (seat.seat_number == row + col) {
          if (seat.is_aisle) aisle = true
        }
      })
      return aisle
    },
    updateColumn(i) {
      let self = this
      this.generated_seats.forEach(function (seat, index) {
        if (index == i) {
          seat.columns = []
          for (let c = 1; c <= seat.col_size; c++) {
            seat.columns.push(self.numberToAlphabet(c))
          }
        }
      })
    },
    alphabethToNumber(letter) {
      // Convert the letter to uppercase to handle both cases
      let uppercaseLetter = letter.toUpperCase();

      // ASCII value of 'A'
      let asciiValueOfA = 'A'.charCodeAt(0);

      // Get the ASCII value of the input letter
      let asciiValueOfInput = uppercaseLetter.charCodeAt(0);

      // Calculate the position by subtracting the ASCII value of 'A'
      let position = asciiValueOfInput - asciiValueOfA + 1;

      // Return the position
      return position;
    },
    numberToAlphabet(number) {
      if (number < 1 || number > 26) {
        return "Number out of range (1-26)";
      }

      return String.fromCharCode(64 + number); // 64 is the ASCII code for 'A'
    },
    handleAddSeats() {
      this.$loading = true
      Vessel.addSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id,
        rows: this.rows,
        columns: this.columns
      })
        .then(result => {
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
    generateSeats(row = 0, col = 0) {
      this.generated_seats = []
      for (let r = 0; r < parseInt(row); r++) {
        let addtlRow = {
          columns: [],
          col_size: 0
        }
        for (let c = 1; c <= col; c++) {
          addtlRow.columns.push(this.numberToAlphabet(c))
        }

        addtlRow.col_size = addtlRow.columns.length

        this.generated_seats.push(addtlRow)
      }
      this.getTakenSeatsFromBooking()
    },
    getTakenSeatsFromBooking() {
      if (!this.booking) return

      this.$loading = true
      Booking.getTakenSeats(this.booking.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.taken_seats_from_booking = result.data.seats
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getSeats() {
      this.$loading = true
      // this.generated_seats = []
      Vessel.getSeats(this.vessel_detail.id, {
        accommodation_id: this.accommodation.id
      })
        .then(result => {
          this.vessel = result.data.vessel
          if (result.data.vessel_seats) this.seats = result.data.vessel_seats
          this.generateSeats(parseInt(result.data.last_row_seat), this.alphabethToNumber(result.data.last_column_seat))
          if ((this.booking || this.ticket) && this.accommodation) {
            this.getBlockedSeats()
          }
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getAccommodations() {
      this.$loading = true
      Accommodation.get({
        params: {}
      })
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getBlockedSeats() {
      this.$loading = true
      let booking = null
      if (this.ticket && !this.booking) booking = this.ticket.booking
      if (!this.ticket && this.booking) booking = this.booking
      if (!this.ticket && !this.booking) return

      BookingBlockedSeat.get(booking.id, { accommodation_id: this.accommodation.id})
        .then(result => {
          this.blocked_seats = result.data.booking_blocked_seats
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleRemoveSeat(row, col) {
      let seat_number = row + col
      if (this.loading) return

      this.loading = true
      Ticket.saveSeatNumber(this.ticket.id, { seat_number: seat_number, remove: 1 })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Seat number removed',
          });
          this.selected_seat_number = null
          this.generated_seats.splice()
          this.generated_seats = this.generated_seats.filter(item => item !== seat_number);
          this.$emit('removeSeat', seat_number)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })

    },
    handleRemoveReservedSeat(blocked_seat) {
      if (this.loading) return

      this.loading = true
      BookingBlockedSeat.delete(this.booking.id, { booking_blocked_seat_id: blocked_seat.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Seat number removed',
          });
          this.getSeats()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    handleReserveSeat(row, col) {
      let seat_number = row + col
      if (this.loading) return

      this.loading = true
      BookingBlockedSeat.create(this.booking.id, { accommodation_id: this.accommodation.id, seat_number: seat_number })
        .then(result => {
          this.blocked_seats.push(result.data.booking_blocked_seat)
          this.taken_seats_from_booking.push(seat_number)
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })

    },
    
  }
}
</script>

<style></style>
