<template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Manual Approval 
            </h4>
          </div>
          <div class="card-body">
            
            <div class="form-group">
              <label>Payment Type (card or qrph)</label>
              <ValidationProvider name="payment_type" rules="required" v-slot="{ passed, failed }">
                <el-input v-model="form.payment_type"  :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                </el-input><br>
                <span class="error-text" v-if="failed">Payment type is required</span>
              </ValidationProvider>
            </div>

            <div class="form-group">
              <label>Send Email</label>
              <el-checkbox v-model="form.send_email"></el-checkbox>
            </div>
          </div>
          <div class="card-footer text-right">
            <p-button type="info" native-type="submit">Submit</p-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    </template>
    
    <script>
    import {
      extend
    } from "vee-validate";
    import {
      required, double
    } from "vee-validate/dist/rules";
    import {
      OnlineBooking
    } from '@/resources/online_booking'

    extend("required", required);
    extend("double", double);
    
    export default {
      props: ['online_booking'],
      data() {
        return {
          form: {
            payment_type: "",
            send_email: true
          },
        };
      },
      watch: {
        online_booking: function () {
          if (this.online_booking) this.form = Object.assign({}, this.online_booking)
        }
      },
      created: function () {
        if (this.online_booking) this.form = Object.assign({}, this.online_booking)
      },
      beforeDestroy() {
        this.form = {
            payment_type: "",
            send_email: true
        }
      },
      methods: {
        
        submit() {
          this.$loading = true
          let form = {
            id: this.form.id,
            payment_type: this.form.payment_type,
            send_email: this.form.send_email
          }
        
          OnlineBooking.manualApprove(this.form.id, form) 
            .then(result => {
              this.$notify({
                type: 'success',
                title: "Success",
                text: 'Transaction has been approved',
              });
              this.$emit('save', true)
            })
            .catch(error => {
    
            }).finally(() => {
              this.$loading = false
            })
        }
      }
    }
    </script>
    
    <style></style>
    