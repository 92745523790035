var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Manual Approval ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Payment Type (card or qrph)")]),_c('ValidationProvider',{attrs:{"name":"payment_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{class:[
                { 'has-danger': failed },
                { 'has-success': passed }],model:{value:(_vm.form.payment_type),callback:function ($$v) {_vm.$set(_vm.form, "payment_type", $$v)},expression:"form.payment_type"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Payment type is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Send Email")]),_c('el-checkbox',{model:{value:(_vm.form.send_email),callback:function ($$v) {_vm.$set(_vm.form, "send_email", $$v)},expression:"form.send_email"}})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Submit")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }