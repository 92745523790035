<template>
  <el-card>
     <h5>Top Highest Trip Sales Today</h5>
    <el-table class="table-striped" header-row-class-name="text-primary" :data="list" style="width: 100%">

      <el-table-column class-name="td-actions" label="Route">
        <template slot-scope="props">
          {{ props.row.code }}
        </template>
      </el-table-column>

      <el-table-column class-name="td-actions" label="Time">
        <template slot-scope="props">
          {{ props.row.time }}
        </template>
      </el-table-column>

      <el-table-column :min-width="120" class-name="td-actions" label="Passengers">
        <template slot-scope="props">
          {{ props.row.count }}
        </template>
      </el-table-column>
      <el-table-column :min-width="120" class-name="td-actions" label="Sales">
        <template slot-scope="props">
          {{ money(props.row.sum_total_fare) }}
        </template>
      </el-table-column>

    </el-table>
  </el-card>
</template>

<script>
import {
  Analytic
} from '@/resources/analytics'

export default {
  data() {
    return {
      list: []
    }
  },

  created: function () {
    this.getData()
  },
  methods: {
    getData() {
      let params = {}
      this.$loading = true
      Analytic.topHighestTripSalesToday(params)
        .then(result => {
          this.list = result.data.list
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style lang="scss"></style>
