import api from './api'

const API = '/api/client/group-tickets'

export const GroupTicket = {
  userTransactions: function(params) {
    return api.get(`${API}/user-transactions`, {params: params})
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(form) {
    return api.create(API, form)
  },
  assignSeatNumberToAll: function (id) {
    return api.update(`${API}/${id}/assign-seat-number`)
  },
  saveAndPrint: function(id, form) {
    return api.update(`${API}/${id}/save-and-print`, form)
  },
  update: function(form) {
    return api.update(API+'/'+form.id, form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
