<template>
  <div class="row" style="padding: 10px;">
    <div class="col-md-12">
      <div  v-if="booking" style="font-size: 35px;">
        <div> {{ booking.route ? booking.route.code: '' }}</div>
      <div class=""><span style="font-weight: bold;">{{ booking.booking_date }} | {{ booking.formatted_time }} | {{ booking.vessel.name }}</span></div>
      </div>
      <div v-if="ticket" style="font-size: 25px;">
        <div >
          <hr>
        <div class="bold">PASSENGER: {{ ticket.ticket_detail && ticket.ticket_detail.passenger ? ticket.ticket_detail.passenger.full_name : ticket.full_name}}</div>
        <div class="bold">SEAT NO: {{ ticket.ticket_seat ? ticket.ticket_seat.seat_number:'' }}</div>
        <hr>
      </div>
      </div>
    </div>
   
    <div class="col-md-12" v-if="ticket" style="height: 600px;overflow: scroll;">

      <vessel-seat-plan-form :accommodation="accommodation" :vessel_detail="ticket.booking.vessel" :ticket="selected"
        :taken_seats="seats" @takeSeat="handleTakeSeat($event)" @removeSeat="handleRemoveSeat($event)"></vessel-seat-plan-form>
    </div>
  </div>
</template>

<script>
import VesselSeatPlanForm from '../Vessels/VesselSeatPlanForm.vue'
import {
  Booking
} from '@/resources/booking'
import {
  Ticket
} from '@/resources/ticket'

export default {
  props: ['ticket', 'booking', 'autoAssign', 'checkOnly', 'accommodation_id', 'accommodations'],
  components: {
    VesselSeatPlanForm
  },
  data() {
    return {
      selected: null,
      seats: [],
      term: null,
      tickets: [],
      accommodation: null
    }
  },
  watch: {
    ticket: function () {
      this.init()
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init() {
      if (this.ticket) {
        this.selected = Object.assign({}, this.ticket)
        this.term = this.ticket.ticket_detail ? this.ticket.ticket_detail.code : this.ticket.code
        this.tickets = [this.ticket]
        this.accommodation = this.ticket.accommodation

        if (this.accommodation_id && this.accommodations) {
          let self = this
          this.accommodations.forEach(accom => {
            if (accom.id == self.accommodation_id) self.accommodation = accom
          });
        }

        if (this.autoAssign) {
          this.handleTakeSeat('auto')
        } else {
          this.getTakenSeats()
        }
      }
    },
    handleRemoveSeat(seat_number) {
      this.$emit('removeSeat', seat_number)
    },
    handleTakeSeat(seatNumber) {
      this.$loading = true
      let booking_id = this.booking ? this.booking.id : this.selected.booking.id
      Ticket.saveSeatNumber(this.selected.id, {
          seat_number: seatNumber,
          booking_id: booking_id,
        check_only: this.checkOnly,
        accommodation_id: this.accommodation_id || this.selected.accommodation.id
        })
        .then(result => {
          if (!this.checkOnly) {
            this.$notify({
              type: 'success',
              title: "Success",
              text: 'Seat number has been assigned',
            });
            this.getTakenSeats()
            if (this.selected.print) {
              this.handleDownloadBoardingPass()
            } else {
              this.$emit('save', result.data.ticket_seat)
            }
          } else {
            this.getTakenSeats(seatNumber)
            this.$emit('available', seatNumber)
           }
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleDownloadBoardingPass() {
      this.$loading = true
      this.$loadingText = "Preparing to generate boarding pass"

      Ticket.downloadBoardingPass(this.selected.id)
        .then(result => {
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
          this.$emit('save', true)
        })
        .catch(() => {


        }).finally(() => {
          this.$loading = false
        })
    },
    getTakenSeats(seatNumber) {
      this.$loading = true
      let booking_id = this.booking ? this.booking.id : this.selected.booking.id
      Booking.getTakenSeats(booking_id, {
          accommodation_id: this.accommodation_id || this.selected.accommodation.id
        })
        .then(result => {
          this.seats = result.data.seats
          this.seats.push(seatNumber)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
