var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Trip' : 'Create Trips')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Days")]),_c('ValidationProvider',{attrs:{"name":"days","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":"","multiple":""},model:{value:(_vm.form.days),callback:function ($$v) {_vm.$set(_vm.form, "days", $$v)},expression:"form.days"}},_vm._l((_vm.days),function(day,i){return _c('el-option',{key:day,attrs:{"value":i,"label":day}},[_vm._v(_vm._s(day))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("At least one day is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Route")]),_c('ValidationProvider',{attrs:{"name":"route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.route_id),callback:function ($$v) {_vm.$set(_vm.form, "route_id", $$v)},expression:"form.route_id"}},_vm._l((_vm.routes),function(route){return _c('el-option',{key:route.id,attrs:{"value":route.id,"label":route.code}},[_vm._v(_vm._s(route.code))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Route is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Vessel")]),_c('ValidationProvider',{attrs:{"name":"vessel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.vessel_detail_id),callback:function ($$v) {_vm.$set(_vm.form, "vessel_detail_id", $$v)},expression:"form.vessel_detail_id"}},_vm._l((_vm.vessels),function(vessel){return _c('el-option',{key:vessel.id,attrs:{"value":vessel.id,"label":vessel.name}},[_vm._v(_vm._s(vessel.name))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Vessel is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Time")]),_c('ValidationProvider',{attrs:{"name":"vessel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('el-time-select',{staticClass:"form-group",class:[
                   { 'has-danger': failed },
                   { 'has-success': passed }],attrs:{"picker-options":{
                    start: '03:00',
                    step: '00:5',
                    end: '20:00'
                  },"placeholder":"Select time"},model:{value:(_vm.form.time),callback:function ($$v) {_vm.$set(_vm.form, "time", $$v)},expression:"form.time"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Time is required")]):_vm._e()]}}],null,true)})],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }