<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update Invoice' : 'Create new Invoice' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Start Date</label>
          <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
            <el-date-picker v-model="form.start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
            </el-date-picker>
            <br><span class="error-text" v-if="failed">Start Date is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
            <label>End Date</label>
            <ValidationProvider name="end_date" rules="required" v-slot="{ passed, failed }">
              <el-date-picker v-model="form.end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              </el-date-picker>
              <br><span class="error-text" v-if="failed">End Date is required</span>
            </ValidationProvider>
          </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Invoice
} from '@/resources/invoice'

extend("required", required);

export default {
  props: ['invoice'],
  data() {
    return {
      form: {
        fein: "",
        name: "",
      }
    };
  },
  watch: {
    client: function () {
      if (this.invoice) this.form = Object.assign({}, this.invoice)
    },
    'form.start_date': function () {
      this.form.end_date = this.form.start_date
    }
  },
  created: function () {
    if (this.invoice) this.form = Object.assign({}, this.invoice)
  },
  beforeDestroy() {
    this.form = {
      fein: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      this.form.start_date = this.$moment(this.form.start_date).format('YYYY-MM-DD')
      this.form.end_date = this.$moment(this.form.end_date).format('YYYY-MM-DD')

      Invoice.autoGenerate(this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Invoice has been created',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
