<template>
  <div v-if="user">
    <h5>Select A Client</h5>
    
    <div class="row">
      <div class="col-md-3" v-for="client in clients" :key="client.id">
        <el-card :body-style="{ padding: '0px' }" shadow="always">
          <img :src="client.logo" class="image">
          <div style="padding: 14px;">
            <span>{{ client.name }}</span>
            <div class="bottom clearfix">
              <el-button type="text" class="button" @click="setClient(client)">Select</el-button>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>

import { User } from '@/resources/user'
import { Client } from '@/resources/client'


export default {
  data() {
    return {
      user: null,
      clients: []
    }
  },
  created: function () {
    this.user = this.$store.state.user
    this.getClients()
  },
  methods: {
    getClients() {
      let params = { term: this.term, page: 1}
      this.$loading = true
      Client.get({ params: params })
        .then(result => {
          if (result.data.clients)
            this.clients = result.data.clients
        })
        .catch(() => {
      
        }).finally(() => {
          this.$loading = false
        })
    },
    setClient(client) {
      this.$loading = true
      User.setClient({client_id: client.id })
        .then(result => {
           window.location.reload();
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}

</script>
<style></style>
