import api from './api'

const API = '/api/client/bookings'

export const BookingBlockedSeat = {
  get: function(booking_id, params) {
    return api.get(API+'/'+booking_id+'/blocked-seats', {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(booking_id, form) {
    return api.create(API+'/'+booking_id+'/blocked-seats', form)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(booking_id,form ) {
    return api.delete(API+'/'+booking_id+'/blocked-seats', form)
  }
}
