<template>
  <div class="row">
    <div class="col-md-3 ">
      <div class="card">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(getTickets)">
            <div class="">
              <div class="card-body">
                <div class="form-group">
                  <label>Start Date</label>
                  <ValidationProvider name="start_date" rules="required" v-slot="{ passed, failed }">
                    <el-date-picker v-model="start_date" type="date" placeholder="Pick a day" class="form-group" :class="[
          { 'has-danger': failed },
          { 'has-success': passed }]">
                    </el-date-picker>
                    <br><span class="error-text" v-if="failed">Start date is required</span>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>End Date</label>
                  <ValidationProvider name="end_date" v-slot="{ passed, failed }">
                    <el-date-picker v-model="end_date" type="date" placeholder="Pick a day" class="form-group" :class="[
          { 'has-danger': failed },
          { 'has-success': passed }]">
                    </el-date-picker>
                    <br><span class="error-text" v-if="failed">End date is required</span>
                  </ValidationProvider>
                </div>

                <div class="form-group" v-if="isClientAccounting()">
                  <label>Branch</label>
                  <el-select class="" v-model="branch_id" placeholder="Branch">
                    <el-option class="select-primary" v-for="(branch, i) in branches" :key="i" :label="branch.name" :value="branch.id">
                    </el-option>
                  </el-select>
                </div>

                
                <div class="form-group" v-if="isManagement() && !isClientAccounting()">
                  <label>Teller</label>
                  <el-select v-model="user_id" filterable remote clearable :remote-method="getTellerUsers"
                    :loading="loading" class="form-group">
                    <el-option v-for="user in $store.state.users" :key="user.id" :label="user.name" :value="user.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="card-footer text-right">
                <el-button type="info" native-type="button" @click="downloadReport()">Download</el-button>
                <el-button type="info" native-type="submit">Search</el-button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="col-md-9 ">
      <div class="row">
        <div class="col-md-4">
          <stats-card :type="'info'" icon="fa fa-ticket" :small-title="'Total Tickets'"
            :title="pagination.total"></stats-card>
        </div>

        <div class="col-md-4">
          <stats-card :type="'info'" icon="fa fa-mail-forward" :small-title="'Total Rescheds'"
            :title="money(total_reschedules)"></stats-card>
        </div>

        <div class="col-md-4">
          <stats-card :type="'warning'" icon="fa fa-upload" :small-title="'Total Upgrades'"
            :title="money(total_upgrades)"></stats-card>
        </div>

        <div class="col-md-4">
          <stats-card :type="'danger'" icon="fa fa-sort-amount-desc" :small-title="'Total Refunds'"
            :title="money(total_refunds)"></stats-card>
        </div>

        <div class="col-md-4">
          <stats-card :type="'primary'" icon="fa fa-newspaper-o" :small-title="'Ticket Sales'"
            :title="money(total)"></stats-card>
        </div>
        <div class="col-md-4">
          <stats-card :type="'success'" icon="fa fa-money" :small-title="'Total Sales'" style="    background: #dffbeb;"
            :title="money(totalSales) "></stats-card>
        </div>
      </div>
    </div>

    <div class="col-md-12" v-if="isClientAccounting() || hasRole('OWNER')">
      <sales-by-branches-list :form="form"></sales-by-branches-list>
    </div>

    <div class="col-md-12 " v-if="!isClientAccounting()">
      <div class="card">
        <div class="card-header">
          <div class="category"><span>Sold Tickets</span></div>
        </div>
        <div class="card-body row">
          <div class="col-sm-6">
            <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
              <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="col-sm-6">
            <div class="pull-right">
              <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
              </fg-input>
            </div>
          </div>
          <div class="col-sm-12 mt-2">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">
              <el-table-column :min-width="50" class-name="td-actions" label="Code">
                <template slot-scope="props">
                  {{ props.row.ticket_detail.code }}
                </template>
              </el-table-column>

              <el-table-column :min-width="150" class-name="td-actions" label="Passenger Name">
                <template slot-scope="props">
                  {{ props.row.ticket_detail.passenger.full_name }}
                </template>
              </el-table-column>

              <el-table-column :min-width="60" class-name="td-actions" label="Ticket">
                <template slot-scope="props">
                  {{ props.row.type == 1 ? 'OW' : 'RT' }} {{
                  $store.state.passenger_types[props.row.passenger_type] }}

                </template>
              </el-table-column>

              <el-table-column :min-width="50" class-name="td-actions" label="Route">
                <template slot-scope="props">
                  {{ props.row.booking.route.code }} {{ props.row.accommodation.code }}

                </template>
              </el-table-column>

              <el-table-column :min-width="60" class-name="td-actions" label="Date/Time">
                <template slot-scope="props">
                  {{ props.row.booking.booking_date }}<br> {{ props.row.booking.formatted_time }}
                </template>
              </el-table-column>
              <el-table-column :min-width="50" class-name="td-actions" label="Total Fare">
                <template slot-scope="props">
                  {{ money(props.row.ticket_detail.total_fare) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="80" class-name="td-actions" label="Created On">
                <template slot-scope="props">
                  <!-- {{ props.row.user.name }} <br> -->
                  {{ props.row.created_on }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="col-sm-6 pagination-info">
            <p class="category">Found {{ pagination.total }} data</p>
          </div>
          <div class="col-sm-6">
            <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
              :total="pagination.total">
            </p-pagination>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Ticket
} from '@/resources/ticket'
import {
  ClientUser
} from '@/resources/client_user'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Report
} from '@/resources/report'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue';
import { mapState } from 'vuex';
import SalesByBranchesList from './SalesByBranchesList.vue';

import {
  Branch
} from '@/resources/branch'


extend("required", required);

export default {
  computed: mapState(['users']),
  components: {
    StatsCard,
    SalesByBranchesList
  },
  data() {
    return {
      showView: false,
      tickets: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      url: null,
      start_date: null,
      end_date: null,
      total: 0,
      total_reschedules: 0,
      total_refunds: 0,
      total_upgrades: 0,
      users: [],
      user_id: null,
      loading: false,
      form: null,
      branch_id: null,
      branches: []
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    this.start_date = this.$moment().format('YYYY-MM-DD')
    this.end_date = this.$moment().format('YYYY-MM-DD')
    if (this.isClientAccounting()) {
      this.getBranches()
    }
    this.getTickets()
    if (this.isManagement()) this.getTellerUsers()
  },
  computed: {
    totalSales: function () {
      return this.total + parseFloat(this.total_reschedules) + parseFloat(this.total_upgrades) - this.total_refunds
    }
  },
  methods: {
    getBranches() {
      this.loading = true
      Branch.get({
        params: {
          page: 1,
          per_page: 9999
        }
      })
        .then(result => {
          this.branches = result.data.branches
          this.branches.unshift({name: 'All', id: null});
        })
        .catch(() => {


        }).finally(() => {
          this.loading = false
        })
    },
    downloadReport() {
      this.$loading = true
      let form = {
        start_date: this.$moment(this.start_date).format('YYYY-MM-DD'),
        end_date: this.$moment(this.end_date).format('YYYY-MM-DD'),
        user_id: this.user_id,
        exclude_online: true,
        branch_id: this.branch_id
      }
      Report.getSalesReport(form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Report has been generated',
          });
          var printWindow = window.open(result.data.url, '_blank');
          printWindow.print();
        })
        .catch(error => {}).finally(() => {
          this.$loading = false
        })
    },
    getDateTime(date) {
      let d = this.$moment(date)
      return d.format('YYYY-MM-DD h:mm a')
    },
    handleClose() {
      this.showView = false
    },
    getTickets() {
      this.handleClose()

      this.start_date = this.$moment(this.start_date).format('YYYY-MM-DD')
      this.end_date = this.$moment(this.end_date).format('YYYY-MM-DD')

      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        start_date: this.start_date,
        end_date: this.end_date,
        user_id: this.user_id,
        exclude_online: true,
        branch_id: this.branch_id
      }

      this.form = params

      this.$loading = true
      Ticket.soldTickets(params)
        .then(result => {
          this.total = result.data.total
          this.total_upgrades = result.data.total_upgrades
          this.total_refunds = result.data.total_refunds
          this.total_reschedules = result.data.total_reschedules

          if (result.data.tickets)
            this.tickets = result.data.tickets
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
