<template>
  <div>
    <div class="row" v-if="booking">
      <div class="col-md-3">
        <el-card>
          <div style="text-align: center;">
            <div style="font-size: 20px;">{{ booking.route.code }}</div>
            <div style="font-size: 50px;font-weight: bold;" class="text-primary">{{ booking.total_completed_tickets }}
            </div>
            <div>(PAX LOAD)</div>
            <hr>
            <el-descriptions title="DETAILS" :column="1">
              <el-descriptions-item label="VESSEL">{{ booking.vessel.name }}</el-descriptions-item>
              <el-descriptions-item label="DATE">{{ booking.booking_date }} / {{ booking.formatted_time
                }}</el-descriptions-item>
              <el-descriptions-item label="BOOKED">{{ booking.total_passengers }}</el-descriptions-item>
              <el-descriptions-item label="TOTAL CAPACITY">{{ booking.capacity }}</el-descriptions-item>
            </el-descriptions>
            <hr>
            <el-descriptions title="ACCOMMODATIONS" :column="1">
              <el-descriptions-item :label="accom.name" v-for="(accom, i) in booking.data.accommodations" :key="i">{{
                accom.total_tickets }}</el-descriptions-item>

            </el-descriptions>
            <hr>
          </div>
        </el-card>
      </div>
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-12">
            <div class="table-container">
              <h5>MESSAGES</h5>
              <el-button size="small" @click="getMessages"><i class="fa fa-refresh"></i> REFRESH</el-button>
              <el-table class="table-striped" header-row-class-name="text-primary" :data="messages" style="width: 100%">
                <el-table-column :min-width="250" fclass-name="td-actions" label="Contact Numbers">
                  <template slot-scope="props">
                    {{ props.row.contact_numbers }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="300" fclass-name="td-actions" label="Message">
                  <template slot-scope="props">
                    {{ props.row.message }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" fclass-name="td-actions" label="Response">
                  <template slot-scope="props">
                    {{ getResponse(props.row.response) }}
                  </template>
                </el-table-column>
                <el-table-column :min-width="150" fclass-name="td-actions" label="Status">
                  <template slot-scope="props">
                    {{ getStatusLabel(props.row.status) }}<br>
                    <span v-if="props.row.user">BY {{ props.row.user.name }}</span>
                  </template>
                </el-table-column>

                <el-table-column :min-width="150" fclass-name="td-actions" label="Action">
                  <template slot-scope="props">
                    <el-button @click="handleSend(props.row)" v-if="props.row.status != 2 && props.row.status != 1"
                      type="primary" size="medium">Send</el-button>
                    <el-button @click="handleDelete(props.row)" v-if="props.row.status == 0 || props.row.status == 3"
                      type="danger" size="medium">Delete</el-button>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="col-md-12">
            <hr>
            <booking-message-form :booking="booking" :contact_numbers="contact_numbers"
              @close="getMessages"></booking-message-form>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {
  Booking
} from '@/resources/booking'
import BookingMessageForm from './BookingMessageForm.vue'


export default {
  components: {
    BookingMessageForm
    
  },
  props: ['booking'],
  data() {
    return {     
      messages: [],
      contact_numbers: [],
      selected: null,
    }
  },
  watch: {
    booking() {
      this.getMessages()
    },
  },
  created: function () {
    this.getMessages()
  },
  methods: {
    handleDelete(message) {
      message.to_delete = true
      Booking.sendMessage(this.booking.id, message)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Message removed',
          });
          this.getMessages()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    handleSend(message) {
      message.to_send = true
      Booking.sendMessage(this.booking.id, message)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Sending text message',
          });
          this.getMessages()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getStatusLabel(status) {
      if (status == 0) return 'New'
      if (status == 1) return 'Sending'
      if (status == 2) return 'Sent'
      if (status == 3) return 'Error'
    },
    getResponse(response) {
      if (!response) return ""
      if (response['error']) {
        return response['error']
      }
      else {
        return "Success"
      }
    },
    getMessages() {
      let params = {

      }
      this.$loading = true
      Booking.getMessages(this.booking.id, params)
        .then(result => {
          this.messages = result.data.messages
          this.contact_numbers = result.data.contact_numbers
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>