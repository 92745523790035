<template>
    <div class="">
      <stats-card :type="'danger'" icon="fa fa-ticket" :small-title="'Tickets Sold Today'" :title="count">
        <div class="stats link" slot="footer" @click="openLink('/client/reports/sold-tickets')">
          <i :class="'fa fa-external-link'"></i>
          View mores
        </div>
      </stats-card>
    </div>
  </template>
  <script>
  
  import { Analytic } from '@/resources/analytics'
  import StatsCard from '../../UIComponents/Cards/StatsCard.vue';
  
  export default {
    components: {
      StatsCard
    },
    data() {
      return {
        count: 0,
      }
    },
  
    created: function () {
      this.getData()
    },
    methods: {
      getData() {
        let params = {}
        this.$loading = true
        Analytic.totalTicketsSoldToday(params)
          .then(result => {
            this.count = result.data.count
          })
          .catch(() => {
  
          }).finally(() => {
            this.$loading = false
          })
      },
    }
  }
  </script>
  <style lang="scss"></style>
  