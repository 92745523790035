<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Approve this transaction
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Amount to Approve</label>
            <ValidationProvider name="approved_amount" rules="required">
              <el-input type="number" v-model="form.approved_amount" :min="0"></el-input>
            </ValidationProvider>
          </div>
          
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

import {
  ClientUserTransactionRecord
} from '@/resources/client/client_user_transaction_record'
import Swal from 'sweetalert2'

extend("required", required);

export default {
  props: ['record'],
  data() {
    return {
      form: {
        approved_amount: 0
      },
    };
  },
  watch: {
    record: function () {
      if (this.record) this.form = Object.assign({}, this.record)
    }
  },
  created: function () {
    if (this.record) this.form = Object.assign({}, this.record)
  },
  methods: {
    async submit() {
      const { value: res } = await Swal.fire({
        title: "Attention!",
        text: "Approving this transaction is irreversible. Do you wish to continue?",
        icon: "warning",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: true
      })

      console.log(res)

      this.$loading = true
      ClientUserTransactionRecord.approve(this.form.id,this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Transaction Approved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
          this.$loading = false
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
