<template>
  <div class="container" v-if="booking && form">
    <div>
      <div style="text-align: center;">
        <div>{{ $store.state.bookings_status[booking.status] }}</div>
        <div style="font-size: 80px;">{{ booking.total_completed_tickets }}</div>
        <strong>booked tickets out of {{ booking.capacity }} slots <span
            v-if="booking.total_passengers > booking.capacity" class="text-red">(Overbooked)</span></strong><br>
        <strong class="text-gray">{{ booking.total_pending_tickets || 0 }} Pending</strong><br>
        <strong class="text-gray">{{ booking.formatted_time }}</strong><br>
        <strong class="text-gray">{{ booking.route.code }}</strong><br>
        <strong class="text-gray">{{ booking.vessel.code }}</strong>
      </div>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <div class="form-group">
          <label>Vessel</label>
          <ValidationProvider name="vessel" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.vessel_detail_id" placeholder="Select" filterable class="form-group" :class="[
    { 'has-danger': failed },
    { 'has-success': passed }]">
              <el-option :value="vessel.id" :label="vessel.name" v-for="vessel in vessels" :key="vessel.id">{{
                vessel.name }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Vessel is required</span>
          </ValidationProvider>
        </div>
        <p-button type="info" native-type="submit">Save</p-button>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>

import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

extend("required", required);

import {
  Vessel
} from '@/resources/vessel'

import {
  Booking
} from '@/resources/booking'


export default {
  props: ['booking'],
  data() {
    return {
      vessels: [],
      form: null
    };
  },
  watch: {
    booking: function () {
      if (this.booking) this.form = Object.assign({}, this.booking)
    }
  },
  created: function () { 
    if (this.booking) this.form = Object.assign({}, this.booking)
    this.getVessels()
  },
  methods: {
    submit() {
      this.$loading = true

      Booking.changeVessel(this.booking.id, { vessel_detail_id: this.form.vessel_detail_id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Vessel has been changed',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getVessels() {
      this.$loading = true
      Vessel.get({})
        .then(result => {
          if (result.data.vessels)
            this.vessels = result.data.vessels
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
