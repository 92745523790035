<template>
  <div>
    <div>
      <el-button v-if="booking_id_from_query" @click="goBack()" size="small"><i class="fa fa-arrow-left"></i>
        Back</el-button>
      <hr>
    </div>
    <div v-if="!booking && !loading">No trip today</div>
    <div class="row" v-if="booking" v-loading="loading">
      <div class="col-md-4">
        <el-card>
          <div style="text-align: center;">
            <div style="font-size: 20px;">{{ booking.route.code }}</div>
            <div style="font-size: 50px;font-weight: bold;" class="text-primary">{{ booking.total_completed_tickets }}
            </div>
            <div>PAX LOAD</div>
            <div>({{ booking.status_label }})</div>
            <hr>
            <el-descriptions title="DETAILS" :column="1">
              <el-descriptions-item label="VESSEL">{{ booking.vessel.name }}</el-descriptions-item>
              <el-descriptions-item label="DATE">{{ booking.booking_date }} / {{ booking.formatted_time
                }}</el-descriptions-item>

              <el-descriptions-item label="BOOKED">{{ booking.total_passengers }}</el-descriptions-item>
              <el-descriptions-item label="CHECKED-IN">{{ booking.data.checked_in_tickets }}</el-descriptions-item>

              <el-descriptions-item label="TOTAL CAPACITY">{{ booking.capacity }}</el-descriptions-item>
              <el-descriptions-item label="ONLINE TICKETS">{{ booking.data.online_tickets_count
                }}</el-descriptions-item>

            </el-descriptions>
            <hr>
            <el-descriptions title="ACCOMMODATIONS" :column="1">
              <el-descriptions-item :label="accom.name" v-for="(accom, i) in booking.data.accommodations" :key="i">{{
                accom.total_tickets }}</el-descriptions-item>

            </el-descriptions>
            <hr>
          </div>
        </el-card>
      </div>
      <div class="col-md-8">
        <div class="" v-if="booking && booking.vessel">
          <vessel-seats-list :vessel="booking.vessel" :booking="booking"></vessel-seats-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Analytic
} from '@/resources/analytics'

import {
  db
} from '@/firebase'
import VesselSeatsList from '../Vessels/VesselSeatsList.vue'

export default {
  components: { VesselSeatsList },
  data() {
    return {
      booking: null,
      loaded: false,
      loading: false,
      booking_id_from_query: null
    }
  },
  created: function () {
    this.booking_id_from_query = this.$route.query.booking_id
    this.getBooking()
  },
  methods: {
    getBooking() {
      let form = { complete: true }
      if (this.booking_id_from_query) form.booking_id = this.booking_id_from_query
      this.loading = true
      this.booking = null
      Analytic.currentBooking(form)
        .then(result => {
          this.booking = result.data.booking
          if (!this.loaded) this.fetchFromFirebase()
        })
        .catch(() => {

        }).finally(() => {
          this.loading = false
        })
    },
    fetchFromFirebase() {
      var self = this
      let path = 'bookings/' + this.booking.id

      var ref = db.ref(path)

      ref
        .orderByChild('timestamp')
        .limitToLast(1) // Limit to the last record (the latest added)
        .on("child_added", (snapshot) => {
          const item = snapshot.val();
          this.getBooking()
          console.log("Booking updated")

        });

        this.loaded = true
    }
  }
}
</script>

<style lang="scss"></style>
