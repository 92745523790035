import api from './api'

const API = '/api/client/vessels'

export const Vessel = {
  removeAllSeats: function(id, form) {
    return api.delete(`${API}/${id}/remove-all-seats`, form)
  },
  updateSeat: function(id, form) {
    return api.create(`${API}/${id}/update-seat`, form)
  },
  addSeats: function(id, form) {
    return api.create(`${API}/${id}/add-seats`, form)
  },
  getSeats: function(id, params) {
    return api.get(`${API}/${id}/seats`, {params: params}) 
  },
  get: function(params) {
    return api.get(API, params)
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(params) {
    return api.create(API, params)
  },
  update: function(params) {
    return api.update(API+'/'+params.id, params)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
