<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update Price' : 'Create New Price' }}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Route</label>
          <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.route_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              <el-option :value="route.id" :label="route.code" v-for="route in routes" :key="route.id">{{ route.code }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Route is required</span>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Accommodation</label>
          <ValidationProvider name="accommodation" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.accommodation_id" placeholder="Select" filterable class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
              <el-option :value="accommodation.id" :label="accommodation.name" v-for="accommodation in accommodations" :key="accommodation.id">{{ accommodation.name }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Accommodation is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Passenger Type</label>
          <ValidationProvider name="passenger_type" rules="required" v-slot="{ passed, failed }">
            <el-select v-model="form.passenger_type" placeholder="Select" filterable class="form-group" :class="[
                  { 'has-danger': failed },
                  { 'has-success': passed }]">
              <el-option :value="i" :label="p" v-for="p,i in $store.state.passenger_types" :key="i">{{ p }}</el-option>
            </el-select><br>
            <span class="error-text" v-if="failed">Passenger type is required</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label>Amount</label>
          <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
            <el-input-number v-model="form.amount" placeholder="0.00" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]">
            </el-input-number><br>
            <span class="error-text" v-if="failed">Amount is required</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Price
} from '@/resources/price'
import {
  Accommodation
} from '@/resources/accommodation'
import {
  Route
} from '@/resources/route'

extend("required", required);

export default {
  props: ['price'],
  data() {
    return {
      form: {
        amount: "",
      },
      accommodations: [],
      routes: []
    };
  },
  watch: {
    price: function () {
      if (this.price) this.form = Object.assign({}, this.price)
    }
  },
  created: function () {
    if (this.price) this.form = Object.assign({}, this.price)
    this.getAccommodations()
    this.getRoutes()
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? Price.update(this.form) : Price.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Price has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getAccommodations() {
      this.$loading = true
      Accommodation.get({})
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      Route.get({})
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
