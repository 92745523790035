import api from './api'

const API = '/api/client/reports'

export const Report = {
  getOnlineSalesReport: function(params) {
    return api.create(API+"/online-sales-report", params)
  },
  getSalesByBranch: function(params) {
    return api.create(API+"/sales-by-branch", params)
  },
  getSalesReport: function(params) {
    return api.create(API+"/sales-report", params)
  },
  getRefundReport: function(params) {
    return api.create(API+"/refund-sales-report", params)
  },
  getRescheduleReport: function(params) {
    return api.create(API+"/reschedule-sales-report", params)
  },
  getUpgradeReport: function(params) {
    return api.create(API+"/upgraded-sales-report", params)
  },
}
