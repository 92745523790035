<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%" :append-to-body="true"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <promo-item-form @save="getPromos()" v-if="showForm" :promo_item="selected"></promo-item-form>
        </div>
      </div>

    </el-drawer>

    <div class="col-md-12 card" v-if="promo">
      <div class="card-header">
        <div class="category">Manage Promo Items for Promo {{ promo.name }}</div>
      </div>
      <div class="card-body row">

        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
   
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="promo_items" style="width: 100%">
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Name">
              <template slot-scope="props">
                {{ props.row.price.route.code }} | {{ props.row.price.accommodation.code }} | {{ $store.state.passenger_types[props.row.price.passenger_type] }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Original Price">
              <template slot-scope="props">
                {{ money(props.row.price.amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Promo Price">
              <template slot-scope="props">
                {{ money(props.row.amount) }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions"
              label="Limit">
              <template slot-scope="props">
                {{ props.row.limit }}
              </template>
            </el-table-column>

            <el-table-column :min-width="50" fixed="right" class-name="td-actions" label="Return amount" v-if="promo && promo.is_round_trip">
                <template slot-scope="props">
                  {{ money(props.row.return_trip_amount) }}
                </template>
              </el-table-column>

           
            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">
                <el-button type="default" link @click="handleEdit(props.row)" size="mini">
                  <i class="fa fa-edit"></i>
                </el-button>
                <confirm-delete @onClick="handleDelete(props.row)" :label="'Delete'"></confirm-delete>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PromoItem
} from '@/resources/promo_item'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import PromoItemForm from './PromoItemForm.vue'

export default {
  props: ['promo'],
  components: {
    ConfirmDelete,
    PromoItemForm
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      promo_items: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 15,
        currentPage: 1,
        total: 0
      },
      term: null,
    }
  },
  watch: {
    promo: function () {
      this.getPromos()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getPromos()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getPromos()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getPromos()
    }
  },
  created: function () {
    this.getPromos()
  },
  methods: {
    handleEdit(model) {
      this.showForm = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showDelete = false
      this.showForm = false
    },
    handleForm() {
      this.showForm = true
      this.selected = {promo_id: this.promo.id}
    },
    handleDelete(model) {
      this.$loading = true
      this.$loadingText = "Removing"

      PromoItem.delete({
        id: model.id,
        promo_id: this.promo.id
      })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Promo has been removed',
          });
          this.getPromos()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPromos() {
      this.handleClose()
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        promo_id: this.promo.id
      }
      this.$loading = true
      PromoItem.get({
        params: params
      })
        .then(result => {
          if (result.data.promo_items)
            this.promo_items = result.data.promo_items
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
