var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update user':'Create new user')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"name":"password","rules":{
              regex: /^(?=.*\d).{8,}$/
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"placeholder":_vm.form.id ? 'No changes will occur to the password if left blank' : 'Will generate default password if leave blank'},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Should consist of a minimum of 8 characters, including numbers")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The email field format is invalid' : null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('el-select',{model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_c('el-option',{attrs:{"value":0,"label":"Pending"}},[_vm._v("Pending")]),_c('el-option',{attrs:{"value":1,"label":"Active"}},[_vm._v("Active")]),_c('el-option',{attrs:{"value":2,"label":"Inactive"}},[_vm._v("Inactive")])],1)],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }