<template>
  <div>
    <loading :loading="loading"></loading>
    <div class="row" v-if="user">
      <div class="col-md-3">
        <UserProfileCard :user="user"></UserProfileCard>
      </div>
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <tabs value="Description" tab-content-classes="text-center">
              <tab-pane title="Account" key="home">
                <user-edit-form :user="user" @close="setUser($event)"></user-edit-form>
              </tab-pane>
              <tab-pane title="Roles" key="roles">
                <user-roles-form :user="user" @update="getUser()"></user-roles-form>
              </tab-pane>
  
            </tabs>
          </div>
        </div>

        
      </div>
    </div>
  </div>
</template>
<script>

  import { User } from '@/resources/user'
  import UserProfileCard from '@/components/UIComponents/Cards/UserProfileCard'
  import {TabPane, Tabs} from '@/components/UIComponents'
import UserEditForm from './UserEditForm.vue'
import UserRolesForm from './UserRolesForm.vue'

  export default{
    components: { UserProfileCard, TabPane, Tabs,UserEditForm, UserRolesForm },
    data () {
      return {
        loading: true,
        user: null
      }
    },
    created: function() {
        this.getUser(this.$route.params.id)
    },
    methods: {
      handleEdit(index, data) {
          console.log(data)
      },
      setUser(user) {
        this.user = user
      },
      getUser() {
        let id = this.$route.params.id
        this.loading = true
        User.show(id)
        .then(result => {
            if(result.data.user) this.user = result.data.user
        })
        .catch( () => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unale to fetch user'
          });

        }).finally( () => {
          this.loading = false
        })
      }
    }
  }
</script>
<style lang="scss">

</style>
