var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booking && _vm.form)?_c('div',{staticClass:"container"},[_c('div',[_c('div',{staticStyle:{"text-align":"center"}},[_c('div',[_vm._v(_vm._s(_vm.$store.state.bookings_status[_vm.booking.status]))]),_c('div',{staticStyle:{"font-size":"80px"}},[_vm._v(_vm._s(_vm.booking.total_completed_tickets))]),_c('strong',[_vm._v("booked tickets out of "+_vm._s(_vm.booking.capacity)+" slots "),(_vm.booking.total_passengers > _vm.booking.capacity)?_c('span',{staticClass:"text-red"},[_vm._v("(Overbooked)")]):_vm._e()]),_c('br'),_c('strong',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.booking.total_pending_tickets || 0)+" Pending")]),_c('br'),_c('strong',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.booking.formatted_time))]),_c('br'),_c('strong',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.booking.route.code))]),_c('br'),_c('strong',{staticClass:"text-gray"},[_vm._v(_vm._s(_vm.booking.vessel.code))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Vessel")]),_c('ValidationProvider',{attrs:{"name":"vessel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
  { 'has-danger': failed },
  { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.vessel_detail_id),callback:function ($$v) {_vm.$set(_vm.form, "vessel_detail_id", $$v)},expression:"form.vessel_detail_id"}},_vm._l((_vm.vessels),function(vessel){return _c('el-option',{key:vessel.id,attrs:{"value":vessel.id,"label":vessel.name}},[_vm._v(_vm._s(vessel.name))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Vessel is required")]):_vm._e()]}}],null,true)})],1),_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)]}}],null,false,236483699)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }