<template>
  <div class="">
    <stats-card :type="'primary'" icon="fa fa-ship" :small-title="getSmallTitle()"
      :title="getTitle()">
      <div class="stats link" slot="footer" @click="openLink('/client/current-booking')">
        <i :class="'fa fa-external-link'"></i>
        View mores
      </div>
    </stats-card>
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue';

export default {
  components: {
    StatsCard
  },
  data() {
    return {
      booking: 0
    }
  },

  created: function () {
    this.getData()
  },
  methods: {
    getTitle() {
      return this.booking ? this.booking.route_code + ': ' + this.booking.total_completed_tickets : ""
    },
    getSmallTitle() {
      return this.booking ? this.booking.booking_date + '/' + this.booking.formatted_time : "No Trip today"
    },
    getData() {
      let params = {}
      this.$loading = true
      Analytic.currentBooking(params)
        .then(result => {
          this.booking = result.data.booking
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
