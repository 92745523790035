import api from './api'

const API = '/api/client/tickets'

export const Ticket = {
  pendingTickets: function(params) {
    return api.get(`${API}/pending-tickets`, {params: params})
  },
  getRejectedList: function(params) {
    return api.get(`${API}/rejected-list`, {params: params})
  },
  fromBooking: function(params) {
    return api.get(`${API}/from-booking`, {params: params})
  },
  checkinTickets: function(params) {
    return api.get(`${API}/checkin-tickets`, {params: params})
  },
  refundedTickets: function(params) {
    return api.get(`${API}/refunded-tickets`, {params: params})
  },
  rescheduledTickets: function(params) {
    return api.get(`${API}/rescheduled-tickets`, {params: params})
  },
  upgradedTickets: function(params) {
    return api.get(`${API}/upgraded-tickets`, {params: params})
  },
  soldTickets: function(params) {
    return api.get(`${API}/sold-tickets`, {params: params})
  },
  get: function(params) {
    return api.get(API, {params: params})
  },
  show: function(id, params) {
    return api.get(API + '/' + id, params)
  },
  create: function(form) {
    return api.create(API, form)
  },
  sendEmailToOnline: function(form) {
    return api.get(`${API}/send-email-online-tickets`, {params: form})
  },
  generateOnlineTickets: function(form) {
    return api.get(`${API}/generate-online-tickets`, {params: form})
  },
  downloadTickets: function(form) {
    return api.create(`${API}/download-tickets`, form)
  },
  downloadGroupTicketBoardingPass: function(params) {
    return api.get(`${API}/download-group-ticket-boarding-pass`, {params: params})
  },
  downloadBoardingPass: function(id, params) {
    return api.get(`${API}/${id}/download-boarding-pass`, {params: params})
  },
  reschedulesHistory: function(id, params) {
    return api.get(`${API}/${id}/reschedules-history`, {params: params})
  },
  downloadTicket: function(id, params) {
    return api.get(`${API}/${id}/download-ticket`, {params: params})
  },
  saveSeatNumber: function(id, form) {
    return api.update(`${API}/${id}/save-seat-number`, form)
  },
  requestReject: function(id, form) {
    return api.update(`${API}/${id}/request-reject`, form)
  },
   updateRejectStatus: function(id, form) {
    return api.update(`${API}/${id}/update-reject-status`, form)
  },
  refund: function(id, form) {
    return api.update(`${API}/${id}/refund`, form)
  },
  board: function(id, form) {
    return api.update(`${API}/${id}/board`, form)
  },
  resched: function(id, form) {
    return api.update(`${API}/${id}/resched`, form)
  },
  upgrade: function(id, form) {
    return api.update(`${API}/${id}/upgrade`, form)
  },
  saveAndPrint: function(id, form) {
    return api.update(`${API}/${id}/save-and-print`, form)
  },
  update: function(form) {
    return api.update(API+'/'+form.id, form)
  },
  delete: function(params) {
    return api.delete(API+'/'+params.id, params)
  }
}
