i<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update No Trip Date' : 'Create New Exemption Date' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Date</label>
            <ValidationProvider name="date" rules="required" v-slot="{ passed, failed }">
              <el-date-picker v-model="form.date" type="date" placeholder="Pick a day" class="form-group" :class="[
    { 'has-danger': failed },
    { 'has-success': passed }]">
              </el-date-picker>
              <br><span class="error-text" v-if="failed">Date is required</span>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label>Description</label>
            <el-input v-model="form.description" type="textarea" :rows="2"></el-input>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  NoTripDate
} from '@/resources/no_trip_date'

extend("required", required);

export default {
  props: ['no_trip_date'],
  data() {
    return {
      form: {
        date: "",
        description: "",
      }
    };
  },
  watch: {
    no_trip_date: function () {
      if (this.no_trip_date) this.form = Object.assign({}, this.no_trip_date)
    }
  },
  created: function () {
    if (this.no_trip_date) this.form = Object.assign({}, this.no_trip_date)
  },
  beforeDestroy() {
    this.form = {
      date: "",
      description: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      this.form.date = this.$moment(this.form.date).format('YYYY-MM-DD')

      let request = this.form.id ? NoTripDate.update(this.form) : NoTripDate.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Date has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
          // let message = error.response.data.message || 'Unable to save this time'
          // this.$notify({
          //   type: 'danger',
          //   title: "Error",
          //   text: message,
          // });
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
