<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            Reschedule Ticket
          </h4>
          <div class="col-md-12" v-if="ticket">
            <div class="bold-text-blue">{{ ticket.ticket_detail.passenger.full_name }}</div>
            <div v-if="ticket.ticket_seat">SEAT NUMBER: {{ ticket.ticket_seat.seat_number }}</div>
            <hr>
          </div>

        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12" v-if="ticket">
              <div>Current Booking Schedule: <br>{{ ticket.booking.booking_date }} - {{ ticket.booking.formatted_time }}
                (
                {{ ticket.booking.route.code }}) <span>{{ ticket.booking.status_label }}</span></div>
            </div>
            <div class="col-md-12">
              <reschedules-history :ticket="ticket"></reschedules-history>
            </div>
            <div class="col-md-12">
              <hr>
              <div>Select New Schedule</div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Booking Date</label>
                <ValidationProvider name="booking_date" rules="required" v-slot="{ passed, failed }">
                  <el-date-picker v-model="form.booking_date" type="date" placeholder="Pick a day" class="form-group"
                    :class="[
                      { 'has-danger': failed },
                      { 'has-success': passed }]">
                  </el-date-picker>
                  <br><span class="error-text" v-if="failed">Booking Date is required</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Route</label>
                <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
                  <el-select @change="searchTime()" v-model="form.route_code" placeholder="Select" filterable
                    class="form-group" :class="[
    { 'has-danger': failed },
    { 'has-success': passed }]">
                    <el-option :value="route.code" :label="route.code" v-for="route in routes" :key="route.code">{{
                      route.code
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Route is required</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Time</label>
                <ValidationProvider name="route" rules="required" v-slot="{ passed, failed }">
                  <el-select v-model="form.time" placeholder="Select" filterable class="form-group" :class="[
                    { 'has-danger': failed },
                    { 'has-success': passed }]">
                    <el-option :value="trip.time" :label="trip.formatted_time" v-for="trip in trips" :key="trip.time">{{
                      trip.formatted_time
                      }}</el-option>
                  </el-select><br>
                  <span class="error-text" v-if="failed">Time is required</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-12" v-if="booking">
              <el-descriptions class="margin-top" title="Summary" :column="3" size="mini" border>
                <el-descriptions-item>
                  <template slot="label">
                    Booking Info
                  </template>
                  {{ booking.vessel.code }}
                </el-descriptions-item>
                <el-descriptions-item v-if="booking">
                  <template slot="label">
                    Capacity
                  </template>
                  {{ capacity }}
                </el-descriptions-item>
                <el-descriptions-item v-if="booking">
                  <template slot="label">
                    Available
                  </template>
                  {{ capacity - total_passengers }}
                </el-descriptions-item>
              </el-descriptions>
            </div>
          </div>
        </div>
      </div>
      <div class=" text-right">
        <el-button type="primary" native-type="submit" plain :disabled="!form.booking_id">SAVE <span v-if="seat_number">
            with seat number: {{ seat_number }}</span> <span>| {{ money(ticket.expected_resched_amount)
            }} CHARGE</span></el-button>
        <hr>
        <div v-if="booking && ticket.booking.id != booking.id" style="text-align: center;">
          <div class="text-primary">Note: You may choose a seat number during revalidation.</div>
          <ticket-assign-seat :ticket="ticket" :booking="booking" @available="setSeatNumber($event)" :checkOnly="true"
            :center="true"></ticket-assign-seat>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";
import {
  Ticket
} from '@/resources/ticket'

import {
  Booking
} from '@/resources/booking'
import moment from 'moment';

import Swal from 'sweetalert2'
import ReschedulesHistory from './ReschedulesHistory.vue';
import TicketAssignSeat from './TicketAssignSeat.vue';

extend("required", required);

export default {
  components: { ReschedulesHistory, TicketAssignSeat },
  props: ['ticket'],
  data
    () {
    return {
      seat_number: null,
      form: {
        booking_date: new Date(),
        time: null,
        route_code: null,
        booking_id: null,
        seat_number: null
      },
      routes: [],
      trips: [],
      total_passengers: 0,
      price: null,
      booking: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      }
    };
  },
  watch: {
    ticket: function () {
      if (this.ticket) {
        this.form = {
          id: this.ticket.id,
          booking_date: this.ticket.booking.booking_date,
          route_code: this.ticket.booking.route.code,
          time: null,
          accommodation_id: this.ticket.accommodation.id
        }
        if (this.ticket.is_open) this.form.booking_date = this.$moment().format('YYYY-MM-DD')

        if (this.routes.length) {
          this.getTrips()
          this.getReschedulesHistory()
        } else {
          this.getRoutes()
        }
      }
    },
    'form.booking_date': function () {
      if (!this.form.route_code) {
        this.getRoutes()
      } else {
        this.clearData()
        this.getTrips()
      }
    },
    'form.time': function () {
      if (this.form.route_code && this.form.time) {
        this.getBooking()
      }
    }
  },
  created: function () {
    if (this.ticket) {
      this.form = {
        id: this.ticket.id,
        booking_date: this.ticket.booking.booking_date,
        route_code: this.ticket.booking.route.code,
        time: null,
        accommodation_id: this.ticket.accommodation.id
      }
      if (this.ticket.is_open) this.form.booking_date = this.$moment().format('YYYY-MM-DD')
      this.getRoutes()
    
    }
  },
  methods: {
    setSeatNumber(seat_number) {
      this.seat_number = seat_number
    },
    searchTime() {
      this.getTrips()
    },
    clearData() {
      this.booking = null
      this.price = null
      this.capacity = 0
      this.total_passengers = 0
      this.form.booking_id = null
      this.seat_number = null
    },
    handleSaveWithSeat() {
      this.submit(true)
    },
    async submit() {
      // let message = this.ticket.resched_type_option != null ? this.$store.state.resched_types[this.ticket.resched_type_option]+' '+this.ticket.rebooking_rate+" %" : ''
      // if (this.ticket.rebooking_rate == 0) message = "(Free)"
      // const { value: res } = await Swal.fire({
      //   title: message + " Rebooking ",
      //   text: "This will charge the ticket " + this.money(this.ticket.expected_resched_amount),
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonText: "Proceed",
      //   confirmButtonClass: "btn btn-success",
      //   cancelButtonClass: "btn btn-info",
      //   buttonsStyling: false
      // })

      // if (!res) return

      this.$loading = true
      this.form.seat_number = this.seat_number
      Ticket.resched(this.form.id, this.form)
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket has been reschedued',
          });

          // var printWindow = window.open(result.data.url, '_blank');
          // printWindow.print();
                
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getBooking() {
      if (!this.form.booking_date) return
      if (!this.form.time) return

      this.$loading = true
      this.clearData()
      Booking.getBooking({
          date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          route_code: this.form.route_code,
          time: this.form.time,
          accommodation_id: this.form.accommodation_id,
          passenger_type: this.form.passenger_type
        })
        .then(result => {
          if (result.data.booking)
            this.booking = result.data.booking
          this.form.booking_id = result.data.booking.id
          this.capacity = result.data.capacity
          this.total_passengers = result.data.total_passengers
          this.price = result.data.price
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    
    getTrips() {
      this.trips = []
      if (!this.form.booking_date) return
      // if (!this.form.route_code) return
      this.$loading = true
      this.form.time = null
      Booking.getTrips({
          date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
          route_code: this.form.route_code,
          per_page: 999,
          time_only: true
        })
        .then(result => {
          if (result.data.trips)
            this.trips = result.data.trips
          if (this.trips.length) this.form.time = this.trips[0].time
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getPriorityRoute() {
      if (this.routes.length) {
        let old_route_code = this.ticket.booking.route.code
        let selected_route_code = null
        this.routes.forEach(function (route) {
          if (old_route_code == route.code) selected_route_code = route.code
        })
        this.form.route_code = selected_route_code

      }
    },
    getRoutes() {
      this.$loading = true
      this.form.route_code = null
 
      Booking.getRoutes({
        date: this.$moment(this.form.booking_date).format('YYYY-MM-DD'),
        per_page: 999
      })
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
          this.getPriorityRoute()
          this.getTrips()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>

<style></style>
