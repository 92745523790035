<template>
<div v-if="user">
  <h5>Dashboard</h5>
  <hr>
  <div v-if="user.client">
    <employee-overview v-if="user.is_employee"></employee-overview>
    <div v-else>
      <div v-if="user.is_client_accounting">
        <client-accounting-overview></client-accounting-overview>
      </div>
      <employer-overview></employer-overview>
    </div>
  </div>
  <div v-else>
    <select-client></select-client>
  </div>
</div>
</template>

<script>
import EmployeeOverview from './EmployeeOverview.vue'
import EmployerOverview from './EmployerOverview.vue'
import SelectClient from './SelectClient.vue'
import { Message } from 'element-ui';
import ClientAccountingOverview from './ClientAccountingOverview.vue';

export default {
  components: {
    EmployeeOverview,
    EmployerOverview,
    SelectClient,
    ClientAccountingOverview
  },
  data() {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user
    if (this.$route.query.login) {
      Message({
        duration: 5000,
        message: 'WELCOME '+this.user.name.toUpperCase(),
        type: 'success'
      });
      this.$router.replace({ query: {} });
    }
    
    if (this.$route.query.refresh) {
      this.$router.push({
        name: 'Home'
      })
      window.location.reload()
    }
  }
}
</script>

<style>

</style>
