<template>
<div class="mb-3">
  <el-drawer title="" :visible.sync="showTickets" direction="btt" :before-close="handleClose" size="90%" :destroy-on-close="true">
    <div>
      <tickets-list :g_ticket="selected" v-if="selected"></tickets-list>
    </div>
  </el-drawer>

  <el-drawer title="" :visible.sync="showAll" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div>
        <user-all-tickets></user-all-tickets>
      </div>
    </el-drawer>

  <div class="row">
    <div class="col-md-2">
      <div style="font-size: 12px;">Search Tickets</div>

      <form @submit.prevent="submit">
        <el-input placeholder="Search by code" v-model="code" class="input-with-select" size="mini">
          <el-button slot="append" icon="el-icon-search" size="mini" type="button" @click="submit"></el-button>
        </el-input>
      </form>
    </div>
    <div class="col-md-10">
      <div style="font-size: 12px;">Recent Transactions</div>
      <el-button v-for="group_ticket in group_tickets" :key="group_ticket.id" size="mini" @click="handleShowTickets(group_ticket)">{{ group_ticket.code }} ({{ group_ticket.tickets_count }})
        Tickets</el-button>

      <el-button size="mini" @click="handleShowAll()">
        Show All <i class="fa fa-eye"></i></el-button>

    </div>
  </div>
</div>
</template>

<script>
import {
  GroupTicket
} from '@/resources/group_ticket'
import TicketsList from './TicketsList.vue'
import UserAllTickets from './UserAllTickets.vue'

export default {
  components: {
    TicketsList,
    UserAllTickets
  },
  props: ['update'],
  data() {
    return {
      selected: null,
      group_tickets: 0,
      pagination: {
        perPage: 6,
        currentPage: 1,
        total: 0
      },
      showTickets: false,
      code: null,
      showAll: false
    }
  },
  watch: {
    update() {
      this.getTransactions()
    }
  },
  created: function () {
    this.getTransactions()
  },
  methods: {
    handleShowAll() {
      this.showAll = true
    },
    handleShowTickets(model) {
      this.showTickets = true
      this.selected = Object.assign({}, model)
    },
    handleClose() {
      this.showTickets = false
      this.showAll = false
    },
    submit() {
      if (!this.code) return
      this.$loading = true
      GroupTicket.show(this.code)
        .then(result => {
          if (result) this.handleShowTickets(result.data.group_ticket)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getTransactions() {
      let params = {
        status: this.status,
        page: this.pagination.currentPage,
        per_page: 5,
        status: 2
      }
      this.$loading = true
      GroupTicket.userTransactions(params)
        .then(result => {
          if (result.data.group_tickets)
            this.group_tickets = result.data.group_tickets
          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
