<template>
<div>
</div>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {}
  },
  created: function () {
    let self = this
    setTimeout(function () {
      self.createIframe()
    }, 500);
  },
  methods: {
    createIframe() {
      var printWindow = window.open(this.url, '_blank');
      printWindow.print();
      this.$emit('print', true)
    }
  }
}
</script>

<style lang="scss"></style>
