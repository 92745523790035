<template>
  <div class="row">
    <el-drawer title="" :visible.sync="showMessages" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="">
        <booking-messages-list :booking="selected" v-if="selected" @close="getBookings"></booking-messages-list>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showTransfer" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="container">
        <booking-transfer :booking="selected" v-if="selected" @save="getBookings"></booking-transfer>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showChangeVessel" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div>
        <change-vessel-form :booking="selected" v-if="selected" @save="getBookings"></change-vessel-form>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showExtraSeats" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="container">
        <booking-extra-seats :booking="selected" v-if="selected" @close="getBookings"></booking-extra-seats>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showPassengers" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div>
        <booking-tickets-list :booking="selected"></booking-tickets-list>
      </div>
    </el-drawer>

    <el-drawer title="" :visible.sync="showStatus" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div>
        <booking-update-status-form :booking="selected" v-if="selected"
          @save="getBookings()"></booking-update-status-form>
      </div>
    </el-drawer>

    <div class="col-lg-12 card">
      <div class="card-header">
        <div class="category">Bookings</div>
      </div>
      <div class="card-body row">
        <div class="col-lg-3 mb-2">
          <el-select class="" v-model="route_id" placeholder="Search by Route" style="width: 100%;">
            <el-option :value="route.id" :label="route.code" v-for="route in routes" :key="route.id">{{ route.code
              }}</el-option>
          </el-select>
        </div>
        <div class="col-lg-3">
          <el-date-picker v-model="date" type="date" placeholder="Pick a day" class="form-group" size="small"
            style="width: 100%;">
          </el-date-picker>
        </div>
        <div class="col-lg-3">

        </div>
        <div class="col-lg-3">
          <div class="pull-right" style="width: 100%;">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-xl-12 mt-2">
          <div class="row">
            <div class="col-xl-3 mb-2" v-for="booking in bookings" :key="booking.id">
              <el-card :class="background(booking)">
                <div style="text-align: center;">
                  <div>{{$store.state.bookings_status[booking.status]}}</div>
                  <div><el-link style="font-size: 80px;" :href="'/client/current-booking?booking_id='+booking.id"
                      class="text-primary">{{
                      booking.total_completed_tickets }}</el-link></div>
                  <strong>booked tickets out of {{ booking.capacity }} slots <span
                      v-if="booking.total_passengers > booking.capacity"
                      class="text-red">(Overbooked)</span></strong><br>
                  <strong class="text-primary">{{ booking.data.checked_in_tickets || 0 }} Checked-In </strong><br>

                  <strong class="text-gray">{{ booking.total_pending_tickets || 0 }} Pending | {{
                    booking.data.online_tickets_count }} Online Tickets</strong><br>
                  <strong class="text-gray">{{ booking.formatted_time }}</strong><br>
                  <strong class="text-gray">{{ booking.route.code }}</strong><br>
                  <strong class="text-info">{{ booking.vessel.code }} <span v-if="booking.status == 2">DEPARTED ({{
                      booking.formatted_departure_time }})</span></strong>
                  <div style="    font-size: 11px;" class="text-gray">
                    <span>REG: {{ getCountByType(booking, 0) }}</span>|<span> SEN: {{ getCountByType(booking, 2)
                      }}</span>|<span> STUD: {{ getCountByType(booking, 1) }}</span>|<span> CHD:
                      {{ getCountByType(booking, 3) }}</span>|<span> PWD:
                      {{ getCountByType(booking, 4) }}</span>|
                  </div>
                </div>
                <hr>
                <div style="text-align: center;">
                  <el-button size="large" plain type="primary" @click="handleCheckin(booking)"
                    v-if="isAllowed('CHECKIN', 'UPDATE') && !booking.is_locked"
                    class="mb-2 btn-block ml-0">CHECKIN</el-button>
                  <el-button size="large" plain type="primary" @click="handleView(booking)"
                    class="mb-2 btn-block ml-0">MANIFEST</el-button>
                  <el-button class="mb-2 btn-block ml-0" size="large" plain type="success"
                    @click="handleShowStatus(booking)"
                    v-if="isAllowed('BOOKINGS', 'UPDATE') && !booking.is_locked">UPDATE
                    STATUS</el-button>

                  <el-dropdown class="btn-block" v-if="isAllowed('BOOKINGS', 'UPDATE') && !booking.is_locked">
                    <el-button type="info">
                      SETTINGS<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                        <el-button size="large" plain type="primary" @click="handleChangeVessel(booking)"
                          class="mb-2 btn-block ml-0" :disabled="booking.status != 1"
                          v-if="isAllowed('BOOKINGS', 'UPDATE')">CHANGE
                          VESSEL</el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button size="large" plain type="primary" @click="handleExtraSeats(booking)"
                          class="mb-2 btn-block ml-0" v-if="isAllowed('BOOKINGS', 'UPDATE')">EXTRA
                          SEATS</el-button>
                      </el-dropdown-item>
                      <el-dropdown-item>
                        <el-button class="mb-2 btn-block ml-0" size="large" plain type="primary"
                          @click="handleTransfer(booking)" v-if="isAllowed('BOOKINGS', 'UPDATE')">TRANSFER</el-button>

                      </el-dropdown-item>

                      <el-dropdown-item>
                        <el-button class="mb-2 btn-block ml-0" size="large" plain type="primary"
                          @click="handleShowMessages(booking)"
                          v-if="isAllowed('BOOKINGS', 'UPDATE')">MESSAGES</el-button>

                      </el-dropdown-item>

                    </el-dropdown-menu>
                  </el-dropdown>

                  <div v-if="booking.is_locked" class="text-gray">LOCKED</div>
                </div>
              </el-card>
            </div>
          </div>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Route
} from '@/resources/route'

import {
  Booking
} from '@/resources/booking'
import ConfirmDelete from '../../Common/ConfirmDelete.vue'
import BookingTicketsList from './BookingTicketsList.vue'
import BookingUpdateStatusForm from './BookingUpdateStatusForm.vue'
import ChangeVesselForm from './ChangeVesselForm.vue'
import BookingExtraSeats from './ExtraSeats/BookingExtraSeats.vue'
import BookingTransfer from './BookingTransfer.vue'
import BookingMessagesList from './Messages/BookingMessagesList.vue'

export default {
  components: {
    ConfirmDelete,
    BookingTicketsList,
    BookingUpdateStatusForm,
    ChangeVesselForm,
    BookingExtraSeats,
    BookingTransfer,
    BookingMessagesList
  },
  data() {
    return {
      showMessages: false,
      showTransfer: false,
      showExtraSeats:false,
      showStatus: false,
      showDelete: false,
      showPassengers: false,
      bookings: [],
      routes: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 20,
        currentPage: 1,
        total: 0
      },
      route_id: null,
      date: new Date(),
      term: null,
      showChangeVessel: false
    }
  },
  watch: {
    date: function () {
      let date = this.$moment(this.date).format('YYYY-MM-DD')
      if (date != this.date) this.$router.replace({ query: { date: date } })

      if (this.pagination.currentPage == 1) {
        this.getBookings()
      } else {
        this.pagination.currentPage = 1
      }
    },
    route_id: function () {
      this.pagination.currentPage = 1
      this.getBookings()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getBookings()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getBookings()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getBookings()
    }
  },
  created: function () {
    let date = this.$route.query.date 
    if (date && date != this.date ) this.date = this.$route.query.date
    this.getBookings()
    this.getRoutes()
  },
  methods: {
    handleShowMessages(booking) {
      this.showMessages = true
      this.selected = Object.assign({}, booking)
    },
    handleTransfer(booking) {
      this.showTransfer = true
      this.selected = Object.assign({}, booking)
    },
    handleExtraSeats(booking) {
      this.showExtraSeats = true
      this.selected = Object.assign({}, booking)
    },
    getCountByType(booking, type) {
      let total = 0
      booking.grouped_passengers.forEach(passenger => {
        if (type == passenger.type) total = passenger.total
      });
      return total
    },
    handleChangeVessel(booking) {
      this.showChangeVessel = true
      this.selected = Object.assign({}, booking)
    },
    handleCheckin(booking) {
      this.$router.push({ name: 'CheckinPassenger', params: { id: booking.id } })
    },
    getRoutes() {
      this.$loading = true
      Route.get({
          per_page: 999
        })
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
            if (this.routes.length) this.route_id = this.routes[0].id
          this.getTrips()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    background(booking) {
      let klass = ''

      switch (booking.status) {
        case 0:
          klass = 'bg-light-red'
          break;
        case 2:
          klass = 'bg-light-green'
          break;
        case 3:
          klass = 'bg-light-gray'
          break;
      }

      return klass
    },
    handleClose() {
      this.showDelete = false
      this.showPassengers = false
      this.showStatus = false
      this.showExtraSeats = false
      this.showChangeVessel = false
      this.showTransfer = false
      this.showMessages = false
    },
    handleView(model) {
      this.showPassengers = true
      this.selected = Object.assign({}, model)
    },
    handleShowStatus(model) {
      this.showStatus = true
      this.selected = Object.assign({}, model)
    },
    getBookings() {
      this.handleClose()
      if (!this.route_id) return false
      let params = {
        date: this.$moment(this.date).format('YYYY-MM-DD'),
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        route_id: this.route_id
      }
      this.$loading = true
      Booking.get({
          params: params
        })
        .then(result => {
          if (result.data.bookings)
            this.bookings = result.data.bookings

          this.pagination = result.data.meta
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
