import api from './api'

const API = '/api/register'

export const Signup = {
  register: function(params) {
    return api.create(API, params)
  },
  forgotPassword: function(params) {
    return api.create('/api/forgot-password', params)
  },
  checkToken: function(params) {
    return api.create('/api/check-token', params)
  },
  updatePassword: function(params) {
    return api.create('/api/update-password', params)
  },
}
