var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Branch' : 'Create New Branch')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Code")]),_c('ValidationProvider',{attrs:{"name":"fein","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The code field format is invalid' : null,"hasSuccess":passed,"name":"code"},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Allowed Routes")]),_c('el-select',{attrs:{"multiple":"","placeholder":"Select"},model:{value:(_vm.form.allowed_routes),callback:function ($$v) {_vm.$set(_vm.form, "allowed_routes", $$v)},expression:"form.allowed_routes"}},_vm._l((_vm.routes),function(route){return _c('el-option',{key:route.id,attrs:{"label":route.code,"value":route.id}})}),1)],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Allowed Accommodations")]),_c('el-select',{attrs:{"multiple":"","placeholder":"Select"},model:{value:(_vm.form.allowed_accommodations),callback:function ($$v) {_vm.$set(_vm.form, "allowed_accommodations", $$v)},expression:"form.allowed_accommodations"}},_vm._l((_vm.accommodations),function(accom){return _c('el-option',{key:accom.id,attrs:{"label":accom.name,"value":accom.id}})}),1)],1)]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }