<template>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" v-loading="loading">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">
              Refund Ticket
            </h4>
          </div>
          <div class="card-body" v-if="ticket">
            <div class="row">
                <div class="col-md-12">
                    <div style="font-weight: bold">{{ ticket.ticket_detail.passenger.full_name }}</div>
                    <div>{{ ticket.booking.route.code }} | {{ ticket.accommodation.name }}</div>
                    <div>{{ ticket.booking.booking_date }} {{ ticket.booking.formatted_time }}</div>
                    <hr>
                </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Ticket Series Number</label>
                  <ValidationProvider name="series_number" rules="required" v-slot="{ passed, failed }">
                    <fg-input name="series_number" :error="failed ? 'Series number is required' : null" :hasSuccess="passed"
                    placeholder="Series number (required)" v-model="form.series_number">
                    </fg-input>
                    </ValidationProvider>
                </div>
                
                </div>
                <div class="col-md-12">
                    <p>Use Ticket code for <strong>Online tickets</strong> and Series number for on-site tickets</p>
                </div>
                <div class="col-md-12" style="font-size: 25px;">
                    <div>A fee of {{ money(ticket.expected_refund_amount) }} will be applied to the ticket.</div>
                    <div>You can return {{  money(ticket.expected_refund_return_amount)  }} to the passenger.</div>
                </div>
            </div>
          </div>
        </div>
        <div class=" text-right" style="margin-bottom: 10px">
          <el-button type="primary" native-type="submit"
            >SUBMIT | {{ ticket.refund_rate }} % Refund Charge</el-button>
        </div>
      </form>
    </ValidationObserver>
  </template>
  
  <script>
  import {
    extend
  } from "vee-validate";
  import {
    required
  } from "vee-validate/dist/rules";
  import {
    Ticket
  } from '@/resources/ticket'

  import Swal from 'sweetalert2'
  
  extend("required", required);
  
  export default {
    props: ['ticket'],
    data() {
      return {
        form: {
          series_number: null
        },
        loading: false
      };
    },
    watch: {
      ticket: function () {
        if (this.ticket) {
          this.form = {
            id: this.ticket.id,
            series_number: this.ticket.online_booking_id ? this.ticket.ticket_detail.code : null
          }
        }
      },
    },
    created: function () {
        if (this.ticket) {
          this.form = {
            id: this.ticket.id,
            series_number: this.ticket.online_booking_id ? this.ticket.ticket_detail.code : null
          }
        }
    },
    methods: {
      submit() {
        this.loading = true
        Ticket.refund(this.ticket.id, this.form)
            .then(result => {
            this.$notify({
                type: 'success',
                title: "Success",
                text: 'Ticket has been refunded',
            });
            this.$emit('save', true)
            })
            .catch(() => {

            }).finally(() => {
            this.loading = false
            })
        },
    }
  }
  </script>
  
  <style></style>
  