<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Branch' : 'Create New Branch' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed"
                name="name" v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Code</label>
            <ValidationProvider name="fein" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The code field format is invalid' : null" :hasSuccess="passed"
                name="code" v-model="form.code">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Allowed Routes</label>
            <el-select v-model="form.allowed_routes" multiple placeholder="Select">
              <el-option v-for="route in routes" :key="route.id" :label="route.code" :value="route.id">
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label>Allowed Accommodations</label>
            <el-select v-model="form.allowed_accommodations" multiple placeholder="Select">
              <el-option v-for="accom in accommodations" :key="accom.id" :label="accom.name" :value="accom.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Branch } from '@/resources/branch'
import { Route } from '@/resources/route'
import { Accommodation } from '@/resources/accommodation'

extend("required", required);

export default {
  props: ['branch'],
  data() {
    return {
      form: {
        code: "",
        name: "",
      },
      routes: [],
      accommodations: []
    };
  },
  watch: {
    branch: function () {
      if (this.branch) this.form = Object.assign({}, this.branch)
    }
  },
  created: function () {
    if (this.branch) this.form = Object.assign({}, this.branch)
    this.getRoutes()
    this.getAccommodations()
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    getAccommodations() {
      this.$loading = true
      Accommodation.get({})
        .then(result => {
          if (result.data.accommodations)
            this.accommodations = result.data.accommodations
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    getRoutes() {
      this.$loading = true
      Route.get({})
        .then(result => {
          if (result.data.routes)
            this.routes = result.data.routes
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
    submit() {
      this.$loading = true

      let request = this.form.id ? Branch.update(this.form) : Branch.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Branch has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {
      
        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
