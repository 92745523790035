var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Contact Number")]),_c('ValidationProvider',{attrs:{"name":"contact_number","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"number","error":failed ? 'The name field is required' : null,"hasSuccess":passed,"name":"contact_number"},model:{value:(_vm.form.contact_number),callback:function ($$v) {_vm.$set(_vm.form, "contact_number", $$v)},expression:"form.contact_number"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The email field format is invalid' : null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Password")]),_c('ValidationProvider',{attrs:{"vid":"confirmation","name":"password","rules":{
required: (_vm.form.password ? true : false),
  regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The Password must be at least 8 characters and has a number' : null,"hasSuccess":passed,"name":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),(_vm.form.password)?_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Confirm Password")]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'The confirm password must match your password.' : null,"hasSuccess":passed,"name":"confirm"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"text-right"},[_c('p-button',{attrs:{"type":"success","native-type":"submit"}},[_vm._v("Save")])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }