<template>
  <div class="row">
    <div class="col-md-12 ">
      <div class="card">
        <div class="card-header">
          <div class="category">Records</div>
        </div>
        <div class="card-body row">
          <div class="col-sm-12 mt-2" v-if="report && report.branches_data">
            <el-table class="table-striped" header-row-class-name="text-primary" :data="report.branches_data"
              style="width: 100%">

              <el-table-column :min-width="120" class-name="td-actions" label="Branch">
                <template slot-scope="props">
                  {{ props.row.branch_name }}
                </template>
              </el-table-column>

              <el-table-column :min-width="60" class-name="td-actions" label="Tickets">
                <template slot-scope="props">
                  {{ props.row.count }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Ticket Sales">
                <template slot-scope="props">
                  {{ money(props.row.total_amount) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Terminal Fee">
                <template slot-scope="props">
                  {{ money(props.row.total_terminal_fee) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="FCA">
                <template slot-scope="props">
                  {{ money(props.row.add_hazard) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Reschedules">
                <template slot-scope="props">
                  {{ money(props.row.add_resched) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Upgrades">
                <template slot-scope="props">
                  {{ money(props.row.add_upgrades) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Refunds">
                <template slot-scope="props">
                  {{ money(props.row.less_refund) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="100" class-name="td-actions" label="Total">
                <template slot-scope="props">
                  {{ money(props.row.total_sales) }}
                </template>
              </el-table-column>

            </el-table>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Report
} from '@/resources/report'
import {
  extend
} from "vee-validate";
import {
  required
} from "vee-validate/dist/rules";

extend("required", required);

export default {
  props:['form'],
  data() {
    return {
      report: null
    }
  },
  watch: {
    form: function () {
      this.getSalesByBranch()
    }
  },
  created: function () {
    this.getSalesByBranch()
  },
  methods: {
    getSalesByBranch() {
      this.$loading = true

      Report.getSalesByBranch(this.form)
        .then(result => {
          this.report = result.data.report
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch data at the moment',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
