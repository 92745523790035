import api from '../api'

const API = '/api/client/invoices'

export const ClientInvoice = {
  get: function(params) {
    return api.get(API, params)
  },
  downloadPdf: function(id, params) {
    return api.create(API + '/' + id + '/download-pdf', params)
  },
}
