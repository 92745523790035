<template>
  <div>
    <div class="row">
      <div class="col-md-12" v-if="user && user.unpaid_invoices">
        <el-alert effect="dark" :closable="false" type="warning" >
          <div style="font-size: 20px;"><i class="fa fa-warning" style="font-size: 50px;"></i>
             <div>You have pending invoice(s). </div>
            <hr v-if="invoices.length">
            <div v-for="invoice in invoices" :key="invoice.id">
              <strong>{{ invoice.title }} </strong> | Amount: {{ money(invoice.total_amount) }}
              <hr>
            </div>
            <br>
            <el-link style="font-size: 20px;color: #fff;"
              href="client/invoices"> Click here to
              view</el-link></div>
        </el-alert>
        <br>
      </div>
    </div>
  </div>
</template>

<script>

import StatsCard from "../../../UIComponents/Cards/StatsCard.vue"

import {
  ClientInvoice
} from '@/resources/client/client_invoice'

export default {
  components: {
    StatsCard,
  },
  data() {
    return {
      user: null,
      invoices: []
    }
  },
  created: function () {
    this.user = this.$store.state.user
    if (this.user && this.user.unpaid_invoices) {
      this.getInvoices()
    }
  },
  methods: {
    getInvoices() {
      let params = {
        order_asc: 1,
        page: 1,
        per_page: 3,
        not_paid: 1
      }
      this.$loading = true
      ClientInvoice.get({
        params: params
      })
        .then(result => {
          this.invoices = result.data.invoices
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style></style>
