<template>
  <div class="row">

    <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="getUsers" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <user-edit-form @close="getUsers()" v-if="showForm" :user="selected"></user-edit-form>
        </div>
      </div>

    </el-drawer>

    <el-dialog title="Attention" :visible.sync="showDelete" :before-close="handleClose">
      <div style="padding: 10px;" v-if="selected">
        <p>Remove this user? </p>
        <p-button type="success" size="sm" icon @click="deleteItem()">
          <i class="fa fa-remove"></i>
        </p-button>
      </div>

    </el-dialog>

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Users</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-6">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
          <el-select class="select-primary" v-model="status" placeholder="Per page">
            <el-option class="select-primary" v-for="item,i in status_list" :key="i" :label="item" :value="i">
            </el-option>
          </el-select>
          <el-button type="primary" size="small" class="ml-2" @click="handleForm">New +</el-button>
        </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="searchQuery"
              addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="users" style="width: 100%">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div style="padding-left: 50px">
                  <p>Roles: {{ props.row.role_names.join(", ") }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-for="column in userColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label">
            </el-table-column>
            <el-table-column :min-width="100"  class-name="td-actions" label="Contact Number">
              <template slot-scope="props">
                {{ props.row.contact_number }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120"  class-name="td-actions" label="Roles">
              <template slot-scope="props">
                {{ props.row.role_names }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Client">
              <template slot-scope="props">
                {{ props.row.client ? props.row.client.name : 'N/A' }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <p-button type="default" link>
                  <router-link :to="{ name: 'UserEdit', params: { id: props.row.id }}"><i
                      class="fa fa-edit"></i></router-link>
                </p-button>

                <p-button type="danger" size="sm" icon @click="handleDelete(props.row)">
                  <i class="fa fa-times"></i>
                </p-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  User
} from '@/resources/user'

import Vue from 'vue'
import {
  Table,
  TableColumn,
  Select,
  Option,
  Drawer
} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import UserEditForm from './UserEditForm.vue'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Drawer)

export default {
  components: {
    PPagination,
    UserEditForm
  },
  data() {
    return {
      showDelete: false,
      showForm: false,
      selected: null,
      users: [],
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      status_list: ['Pending', 'Active', 'Inactive'],
      status: 1,
      searchQuery: '',
      propsToSearch: ['name', 'email', 'age'],
      userColumns: [{
          prop: 'name',
          label: 'Name',
          minWidth: 200
        },
        {
          prop: 'email',
          label: 'Email',
          minWidth: 250
        }
      ],
    }
  },
  watch: {
    status: function () {
      this.getUsers()
    },
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getUsers()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getUsers()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getUsers()
    }
  },
  created: function () {
    this.getUsers()
  },
  methods: {
    handleClose() {
      this.showDelete = false
        this.showForm = false
    },
    async handleDelete(object) {
      const result = await this.confirmRemove("Are you sure you want to remove this user?");
      if (!result) return false
       
      this.$loading = true
      this.$loadingText = "Removing"
      
      User.delete({ id: object.id })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'User has been removed',
          });
          this.getUsers()
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })

    },
     handleForm() {
      this.showForm = true
    },
    getUsers() {
      this.handleClose()
      User.get({
          params: {
            term: this.term,
            status: this.status,
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
          }
        })
        .then(result => {
          if (result.data.users)
            this.users = result.data.users
          this.pagination = result.data.meta
        })
        .catch(() => {
       

        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">

</style>
