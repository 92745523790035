var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.booking && _vm.form)?_c('div',{staticClass:"container"},[_c('div',{staticStyle:{"font-weight":"bold","font-size":"20px","margin-bottom":"20px"}},[_vm._v("SEND A NEW MESSAGE")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Contact Numbers")]),_vm._l((_vm.contact_numbers),function(contact_number,i){return _c('el-tag',{key:i},[_vm._v(_vm._s(contact_number.contact_number))])})],2),_c('div',[_c('p',[_vm._v("CLICK TO USE THESE MESSAGE TEMPLATES")]),_vm._l((_vm.getHelperMessages()),function(text,i){return _c('div',{key:i},[_c('el-alert',[_c('span',[_vm._v(" "+_vm._s(text))]),_c('br'),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){return _vm.useMessage(text)}}},[_vm._v("Use this")])],1)],1)})],2),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Message")]),_c('ValidationProvider',{attrs:{"name":"vessel","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-input',{staticClass:"form-group",class:[
  { 'has-danger': failed },
  { 'has-success': passed }],attrs:{"rows":5,"type":"textarea","placeholder":"Type your message"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('br'),_vm._v(" "),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Message is required")]):_vm._e(),_c('div',[_vm._v(" "+_vm._s(_vm.form.message.length)+" of 160 characters. (You are only allowed to send up 160 characters)")])]}}],null,true)})],1),(_vm.form.message.length && _vm.form.message.length <= 160)?_c('el-button',{attrs:{"size":"medium","plain":"","type":"primary","native-type":"submit"}},[_vm._v("Save only")]):_vm._e(),(_vm.form.message.length && _vm.form.message.length <= 160)?_c('confirm-button',{staticClass:"mt-3",attrs:{"title":"You're about to send this message. Proceed?","label":"Save and Send","size":'medium',"type":'primary'},on:{"onClick":function($event){return _vm.submit(true)}}}):_vm._e()],1)]}}],null,false,212536955)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }