<template>
  <div class="">
    <stats-card :type="'success'" icon="fa fa-check" :small-title="'Departed Trips Today'" :title="getTitle()">
      <div class="stats link" slot="footer" @click="openLink('/client/bookings')">
        <i :class="'fa fa-external-link'"></i>
        View mores
      </div>
    </stats-card>
  </div>
</template>
<script>

import { Analytic } from '@/resources/analytics'
import StatsCard from '../../UIComponents/Cards/StatsCard.vue';

export default {
  components: {
    StatsCard
  },
  data() {
    return {
      count: 0,
      total_passengers: 0
    }
  },

  created: function () {
    this.getData()
  },
  methods: {
    getTitle(){
      let title = this.count+' TRIP / '+this.total_passengers+' PAX'
      if (this.count > 1) title = this.count+' TRIPS / '+this.total_passengers+' PAX'
      return title
    },
    getData() {
      let params = {}
      this.$loading = true
      Analytic.departedTripsToday(params)
        .then(result => {
          this.count = result.data.count
          this.total_passengers = result.data.total_passengers
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    },
  }
}
</script>
<style lang="scss"></style>
