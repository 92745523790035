<template>
  <ValidationObserver v-slot="{ handleSubmit }" v-loading="loading">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update role' : 'Create new role' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>

          <div class="form-group">
              <label>Code</label>
              <ValidationProvider name="code" rules="required" v-slot="{ passed, failed }">
                <fg-input type="text" :error="failed ? 'The code field is required' : null" :hasSuccess="passed" name="code"
                  v-model="form.code">
                </fg-input>
              </ValidationProvider>
            </div>

        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>

import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { Role } from '@/resources/role'

extend("email", email);
extend("required", required);

export default {
  props: ['role'],
  data() {
    return {
      form: {
        code: "",
        name: "",
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.role)
  },
  methods: {
    submit() {
      this.loading = true

      let request = this.form.id ? Role.update(this.form) : Role.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Role has been saved',
          });
          this.$emit('update', true)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
