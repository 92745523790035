<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">
            {{ form.id ? 'Update Vessel' : 'Create New Vessel' }}
          </h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label>Name</label>
            <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name"
                v-model="form.name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label>Code</label>
            <ValidationProvider name="fein" rules="required" v-slot="{ passed, failed }">
              <fg-input type="text" :error="failed ? 'The code field format is invalid' : null" :hasSuccess="passed"
                name="code" v-model="form.code">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="card-footer text-right">
          <p-button type="info" native-type="submit">Save</p-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Vessel } from '@/resources/vessel'

extend("required", required);

export default {
  props: ['vessel'],
  data() {
    return {
      form: {
        code: "",
        name: "",
      },
    };
  },
  watch: {
    vessel: function () {
      if (this.vessel) this.form = Object.assign({}, this.vessel)
    }
  },
  created: function () {
    if (this.vessel) this.form = Object.assign({}, this.vessel)
  },
  beforeDestroy() {
    this.form = {
      code: "",
      name: "",
    }
  },
  methods: {
    submit() {
      this.$loading = true

      let request = this.form.id ? Vessel.update(this.form) : Vessel.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Vessel has been saved',
          });
          this.$emit('save', true)
        })
        .catch(error => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
<style></style>
