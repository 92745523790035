var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" Upgrade Ticket ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[(_vm.ticket)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"bold-text-blue"},[_vm._v(_vm._s(_vm.ticket.ticket_detail.passenger.full_name))]),(_vm.ticket.ticket_seat)?_c('div',[_vm._v("SEAT NUMBER: "+_vm._s(_vm.ticket.ticket_seat.seat_number))]):_vm._e(),_c('hr'),_c('div',[_vm._v("Current Accommodation: "+_vm._s(_vm.ticket.accommodation.name))])]):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("New Accommodation")]),_c('ValidationProvider',{attrs:{"name":"accommodation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{staticClass:"form-group",class:[
                  { 'has-danger': failed },
                  { 'has-success': passed }],attrs:{"placeholder":"Select","filterable":""},model:{value:(_vm.form.new_accommodation_id),callback:function ($$v) {_vm.$set(_vm.form, "new_accommodation_id", $$v)},expression:"form.new_accommodation_id"}},_vm._l((_vm.accommodations),function(accommodation){return _c('el-option',{key:accommodation.id,attrs:{"value":accommodation.id,"label":accommodation.name}},[_vm._v(_vm._s(accommodation.name))])}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Accommodation is required")]):_vm._e()]}}],null,true)})],1),(!_vm.booking)?_c('el-button',{attrs:{"type":"primary","native-type":"submit","plain":"","disabled":!_vm.form.new_accommodation_id}},[_vm._v("Check")]):_vm._e()],1),_c('div',{staticClass:"col-md-12"},[(_vm.booking)?_c('el-alert',{attrs:{"type":"success"}},[_vm._v("Upgrade is available")]):_vm._e()],1)])])]),_c('div',{staticClass:" text-right",staticStyle:{"margin-bottom":"10px"}},[(_vm.booking)?_c('el-button',{attrs:{"type":"primary","native-type":"button","disabled":!_vm.form.new_accommodation_id},on:{"click":_vm.onSubmit}},[_vm._v("SAVE "),(_vm.seat_number)?_c('span',[_vm._v("With Seat Number "+_vm._s(_vm.seat_number))]):_vm._e(),_c('span',[_vm._v(" | "+_vm._s(_vm.money(_vm.getDifference()))+" CHARGE ")])]):_vm._e()],1),(_vm.booking && _vm.ticket)?_c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticClass:"text-primary"},[_vm._v("Note: You may choose a seat number during upgrade.")]),_c('ticket-assign-seat',{attrs:{"ticket":_vm.ticket,"booking":_vm.ticket.booking,"checkOnly":true,"center":true,"accommodation_id":_vm.form.new_accommodation_id,"accommodations":_vm.accommodations},on:{"available":function($event){return _vm.setSeatNumber($event)}}})],1):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }