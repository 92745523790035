<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4">
            <stats-card :type="'primary'" icon="fa fa-globe" :small-title="'Accrued Sick/Vac this year'" :title="'12.00/3.00'">
                  <div class="stats" slot="footer">
                    <i :class="'fa fa-external-link'"></i>
                    View more
                  </div>
                </stats-card>
          </div>
          <div class="col-md-4">
              <stats-card :type="'warning'" icon="fa fa-money" :small-title="'Accumulated earnings this year'" :title="'$1,000.00'">
                <div class="stats" slot="footer">
                  <i :class="'fa fa-external-link'"></i>
                  View more
                </div>
              </stats-card>
          </div>
          <div class="col-md-4">
            <stats-card :type="'danger'" icon="fa fa-clock-o" :small-title="'Latest Time-in'" :title="'07:50 AM'">
                  <div class="stats" slot="footer">
                    <i :class="'fa fa-external-link'"></i>
                    View more
                  </div>
                </stats-card>
          </div>
        </div>
        <el-card class="mt-3">
          <h5><i class="fa fa-check-square-o mr-2"></i>TODO LIST</h5>
          <p>Nothing to do yet</p>
        </el-card>
      </div>
      <div class="col-md-4">
        <!-- <el-card>
          <h5 ><i class="fa fa-bookmark mr-2"></i>SHORTCUTS</h5>

        </el-card> -->
        <el-card class="">
          <h5><i class="fa fa-calendar-o mr-2"></i>Upcoming Events</h5>
        </el-card>
      </div>
    </div>
</div>
</template>
<script>
import StatsCard from "../../../UIComponents/Cards/StatsCard.vue"


export default {
  components: { StatsCard },
  data() {
    return {
      user: null
    }
  },
  created: function () {
    this.user = this.$store.state.user

  }
}

</script>
<style></style>
