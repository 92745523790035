var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.form.id ? 'Update Invoice '+_vm.form.invoice_number : 'Create new Invoice')+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Status")]),_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.$store.state.invoice_status),function(item,i){return _c('el-option',{key:i,class:[
                     { 'has-danger': failed },
                     { 'has-success': passed }],attrs:{"label":item,"value":i}})}),1),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Status is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Start Date")]),_c('ValidationProvider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                     var passed = ref.passed;
                     var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Start Date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("End Date")]),_c('ValidationProvider',{attrs:{"name":"end_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
              { 'has-danger': failed },
              { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("End Date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Due Date")]),_c('ValidationProvider',{attrs:{"name":"due_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var passed = ref.passed;
              var failed = ref.failed;
return [_c('el-date-picker',{staticClass:"form-group",class:[
                { 'has-danger': failed },
                { 'has-success': passed }],attrs:{"type":"date","placeholder":"Pick a day"},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}}),_c('br'),(failed)?_c('span',{staticClass:"error-text"},[_vm._v("Due Date is required")]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The title field format is invalid' : null,"hasSuccess":passed,"name":"code"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Remarks")]),_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.remarks),callback:function ($$v) {_vm.$set(_vm.form, "remarks", $$v)},expression:"form.remarks"}})],1),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"50%"}},[_vm._v("Description")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Quantity")]),_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Unit Price")]),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v("Total")]),_c('th')])]),_c('tbody',[_vm._l((_vm.form.invoice_items || []),function(invoice_item,i){return _c('tr',{key:invoice_item.id},[_c('td',[_c('ValidationProvider',{attrs:{"name":'item_name_'+i,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var passed = ref.passed;
                var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"textarea","error":failed ? 'Item description is required' : null,"hasSuccess":passed,"name":"code"},model:{value:(invoice_item.item_name),callback:function ($$v) {_vm.$set(invoice_item, "item_name", $$v)},expression:"invoice_item.item_name"}})]}}],null,true)})],1),_c('td',[_c('ValidationProvider',{attrs:{"name":'quantity_' + i,"rules":""}},[_c('el-input',{attrs:{"min":0,"type":"number","step":".01"},model:{value:(invoice_item.quantity),callback:function ($$v) {_vm.$set(invoice_item, "quantity", $$v)},expression:"invoice_item.quantity"}})],1)],1),_c('td',[_c('ValidationProvider',{attrs:{"name":'unit_price_' + i,"rules":""}},[_c('el-input',{attrs:{"min":0,"type":"number","step":".01"},model:{value:(invoice_item.unit_price),callback:function ($$v) {_vm.$set(invoice_item, "unit_price", $$v)},expression:"invoice_item.unit_price"}})],1)],1),_c('td',{staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.money(invoice_item.quantity * invoice_item.unit_price))+" ")]),_c('td',{staticStyle:{"text-align":"right"}},[_c('el-button',{staticClass:"text-danger",attrs:{"type":"text"},on:{"click":function($event){return _vm.handleRemove(invoice_item)}}},[_c('i',{staticClass:"fa fa-trash"})])],1)])}),_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.money(_vm.getTotal())))])]),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('el-button',{attrs:{"text":""},on:{"click":_vm.handleAddItem}},[_vm._v("ADD ITEM "),_c('i',{staticClass:"fa fa-plus"})])],1)])],2)])]),_c('div',{staticClass:"card-footer text-right"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Save")])],1)])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }