<template>
  <div>
    <h4>MANAGE EXTRA SEATS</h4>
    <el-table class="table-striped" header-row-class-name="text-primary" :data="extra_seats" style="width: 100%">
      <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="accommodation">
        <template slot-scope="props">
          {{ props.row.accommodation.name }}
        </template>
      </el-table-column>

      <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="number of extra seats">
        <template slot-scope="props">
          <el-input type="number" v-model="props.row.number_of_seats"></el-input>
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-button type="primary" plain size="small" @click="handleSave">SAVE</el-button>
    </div>
  </div>
</template>

<script>

import {
  Booking
} from '@/resources/booking'

export default {
  props:['booking'],
  data() {
    return {
      extra_seats: []
    };
  },
  watch: {
    booking: function () {
      this.configure()
    }
  },
  created: function () {
    if (this.booking)  this.configure()
   },
  methods: {
    configure() {
      let self = this
      this.booking.extra_seats.forEach(seat => {
        self.extra_seats.push(seat)
      });
    },
    handleSave() {
      this.$loading = true
      Booking.saveExtraSeats(this.booking.id, { extra_seats: this.extra_seats })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Extra seats updated',
          });
          this.$emit('close', true)
        })
        .catch(() => {

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>
