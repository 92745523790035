<template>
<ValidationObserver v-slot="{ handleSubmit }">
  <form @submit.prevent="handleSubmit(submit)"  v-loading="loading">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">
          {{ form.id ? 'Update user':'Create new user'}}
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Name</label>
          <ValidationProvider name="name" rules="required" v-slot="{ passed, failed }">
            <fg-input type="text" :error="failed ? 'The name field is required' : null" :hasSuccess="passed" name="name" v-model="form.name">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="form-group">
            <label>Password</label>
            <ValidationProvider name="password" :rules="{
              regex: /^(?=.*\d).{8,}$/
            }" v-slot="{ passed, failed }">
              <el-input :placeholder="form.id ? 'No changes will occur to the password if left blank' : 'Will generate default password if leave blank'" v-model="form.password" class="form-group" :class="[
                { 'has-danger': failed },
                { 'has-success': passed }]"></el-input>
              <span class="error-text" v-if="failed">Should consist of a minimum of 8 characters, including numbers</span>
            </ValidationProvider>
          </div>
        <div class="form-group">
          <label>Email</label>
          <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
            <fg-input type="text" :error="failed ? 'The email field format is invalid' : null" :hasSuccess="passed" name="email" v-model="form.email">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="form-group">
          <label>Status</label>
           <el-select v-model="form.status">
            <el-option :value="0" label="Pending">Pending</el-option>
            <el-option :value="1" label="Active">Active</el-option>
            <el-option :value="2" label="Inactive">Inactive</el-option>
          </el-select> 
        </div>
      </div>
      <div class="card-footer text-right">
        <p-button type="info" native-type="submit">Save</p-button>
      </div>
    </div>
  </form>
</ValidationObserver>
</template>

<script>
import {
  extend
} from "vee-validate";
import {
  required,
  regex,
  email
} from "vee-validate/dist/rules";
import {
  User
} from '@/resources/user'

extend("email", email);
extend("required", required);
extend("regex", regex);

export default {
  props: ['user'],
  data() {
    return {
      form: {
        email: "",
        name: "",
      },
      loading: false
    };
  },
  created: function () {
    this.form = Object.assign({}, this.user)
  },
  methods: {
    submit() {
      this.loading = true

      let request = this.form.id ? User.update(this.form) : User.create(this.form)

      request
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'User has been saved',
          });
          this.$emit('close', result.data.user)
        })
        .catch(error => {
          let message = error.response.data.message || 'Unable to save this time'
          this.$notify({
            type: 'danger',
            title: "Error",
            text: message,
          });
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style></style>
