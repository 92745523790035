<template>
  <div class="row">
    <!-- <el-drawer title="" :visible.sync="showForm" direction="btt" :before-close="handleClose" size="90%"
      :destroy-on-close="true">
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <port-form @save="getPorts()" v-if="showForm" :port="selected"></port-form>
        </div>
      </div>

    </el-drawer> -->

    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">Manage Rejected Tickets</div>
      </div>
      <div class="card-body row">
        <div class="col-sm-2">
          <el-select class="select-default" v-model="pagination.perPage" placeholder="Per page" size="small">
            <el-option class="select-default" v-for="item in perPageOptions" :key="item" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-2">
          <el-select class="select-default" v-model="reject_status" placeholder="Per page" size="small"
            @change="getTickets">
            <el-option class="select-default" :value="1" label="Pending">
            </el-option>
            <el-option class="select-default" :value="2" label="Approved">
            </el-option>
          </el-select>
        </div>
        <div class="col-sm-2"></div>
        <div class="col-sm-6">
          <div class="pull-right">
            <fg-input class="input-sm" placeholder="Search" v-model="term" addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped" header-row-class-name="text-primary" :data="tickets" style="width: 100%">

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Group Code">
              <template slot-scope="props">
                {{ props.row.group_code }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Passenger Name">
              <template slot-scope="props">
                {{ props.row.ticket_detail.full_name }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Issued By">
              <template slot-scope="props">
                {{ props.row.created_by }}
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Reject Status">
              <template slot-scope="props">
                <el-tag type="info" v-if="props.row.reject_status == 1">Pending</el-tag>

                <el-tag type="danger" v-if="props.row.reject_status == 2">Rejected</el-tag>
              </template>
            </el-table-column>

            <el-table-column :min-width="120" fixed="right" class-name="td-actions" label="Actions">
              <template slot-scope="props">

                <el-tooltip class="item" effect="dark" content="Approve" placement="top"
                  v-if="props.row.reject_status == 1">
                  <el-button type="primary" link @click="handleReject(props.row, 'approve')" size="mini" plain>
                    <i class="fa fa-check"></i>
                  </el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Reject" placement="top"
                  v-if="props.row.reject_status == 1">
                  <el-button type="warning" link @click="handleReject(props.row, 'reject')" size="mini" plain>
                    <i class="fa fa-times"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Found {{ pagination.total }} data</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Ticket
} from '@/resources/ticket'

export default {
  components: {

  },
  data() {
    return {
      showForm: false,
      tickets: [],
      selected: null,
      perPageOptions: [5, 10, 15],
      pagination: {
        perPage: 10,
        currentPage: 1,
        total: 0
      },
      term: null,
      reject_status: 1
    }
  },
  watch: {
    term: function () {
      if (!this.term || this.term.length > 2) {
        this.getTickets()
      }
    },
    'pagination.perPage': function () {
      if (this.pagination.currentPage == 1) {
        this.getTickets()
      } else {
        this.pagination.currentPage = 1
      }
    },
    'pagination.currentPage': function () {
      this.getTickets()
    }
  },
  created: function () {
    this.getTickets()
  },
  methods: {
    handleReject(ticket, type) {
      let status = type == 'approve' ? 2:0
      Ticket.updateRejectStatus(ticket.id, { status: status })
        .then(result => {
          this.$notify({
            type: 'success',
            title: "Success",
            text: 'Ticket updated',
          });
          this.getTickets()
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to update status',
          });

        }).finally(() => {
          this.$loading = false
        })
    },
    handleForm() {
      this.showForm = true
      this.selected = null
    },
    getTickets() {
      let params = {
        term: this.term,
        status: this.status,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        reject_status: this.reject_status
      }
      this.$loading = true
      Ticket.getRejectedList(params)
        .then(result => {
          if (result.data.tickets)
            this.tickets = result.data.tickets
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            title: "Error",
            text: 'Unable to fetch tickets',
          });

        }).finally(() => {
          this.$loading = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
